.feed-sharing-modal {
  .ant-modal-body {
    padding: 45px 64px 64px 64px !important;
    width: 1260px;
    height: 790px;
  }
  .ant-modal-content {
    width: 1260px !important;
  }

  .head-title {
    padding: 0 47px 14.92px 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1135px;
  }
  .back-container {
    width: 1125px;
    height: 640px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #f5f4f9;
    padding: 34px 0;
    position: relative;
    z-index: 999;
    .topic {
      color: #1d1d4e;
      text-align: center;
      font-family: Poppins !important;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      width: 633px;
      margin: auto;
      span {
        position: relative;
        background-color: transparent;
        z-index: 1;
        color: #1d1d4e;
        text-align: center;
        font-family: Poppins !important;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        width: 633px;
        margin: auto;
      }
      span::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: #2dddc3;
        z-index: -1;
      }
    }
    .logos {
      .reuters {
        padding-left: 111px;
        padding-top: 74px;
        position: absolute;

        .reuters-line {
          padding: 5px 0 0 1px;
        }
      }
      .x-logo {
        margin-left: 132px;
        margin-bottom: 94px;
        position: absolute;
        bottom: 0;
        z-index: 888;

        .x-line {
          padding-left: 62px;
          margin-bottom: -38px;
          z-index: 99;
          position: relative;
        }
        .schwab-line {
          padding-left: 40px;
          margin-bottom: -160px;
          z-index: 99;
          position: relative;
        }
      }
      .cme-logo {
        margin-left: 80%;
        margin-bottom: 312px;
        position: absolute;
        bottom: 0;
        z-index: 888;

        .bloomberge_img {
          width: 96px;
          height: 96px;
        }

        .cme-line {
          margin-left: -21px;
          margin-bottom: -21px;
          z-index: 99;
          position: relative;
        }
      }
      .trading-view-logo {
        margin-left: 85%;
        margin-bottom: 117.19px;
        position: absolute;
        bottom: 0;
        z-index: 888;
      }

      .btn-container {
        display: flex;
        width: 100%;
        margin: auto;
        justify-content: center;
        .add-btn {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          background: #4f48e2;
          border: none;
          color: #fff;
          text-align: center;
          font-family: Poppins !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          cursor: pointer;
        }

        .dismiss-btn {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          color: #4f48e2;
          text-align: center;
          font-family: Poppins !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          border: none;
          background-color: #f5f4f9;
        }
      }

      .shared-feed-container-prediction {
        position: relative;
        width: 300px;
        height: max-content;
        flex-shrink: 0;
        border-radius: 12px;
        background: #fff;
        margin: 22px auto;
        z-index: 99999;
        overflow: hidden;
      }

      .shared-feed-container {
        position: relative;
      }

      .topic {
        .topic-left,
        .topic-right {
          display: flex;
          align-items: center;
        }

        .topic-name {
          font-size: 18px;
        }

        .reload-btn {
          margin-left: 10px;
        }
      }

      .feed-blur-premium-apps {
        position: absolute;
        top: 156px;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          359deg,
          rgba(214, 214, 222, 0.4) 0.7%,
          rgba(214, 214, 222, 0) 98.89%
        );
        z-index: 2;
        backdrop-filter: blur(5px);

        .buy-now-box {
          display: inline-flex;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 3.49px 13px 0px rgba(0, 0, 0, 0.05);
          position: absolute;
          top: 15%;
          left: 15%;
          width: 75%;
        }

        .buy-now-text {
          color: #1d1d4e;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          span {
            color: #1d1d4e;
            text-align: center;
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .total-feed {
        height: 84%;
        overflow: auto;
        margin-top: -12px;
        scrollbar-width: none; /* Firefox */
      }

      .hidden-feed {
        overflow: hidden !important;
      }

      .total-feed::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      .feed-items {
        padding: 10px;
        transition: filter 0.3s ease; // Smooth transition
        z-index: 1;

        &.blurred {
          filter: blur(5px); // Add blur effect
        }
      }

      .feed-blur-premium-apps.active ~ .feed-items {
        filter: blur(5px); // Apply blur when overlay is active
      }

      .shared-feed-container {
        width: 410px;
        height: 410px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #fff;
        margin: 22px auto;
        z-index: 99999;
        overflow: hidden;
      }

      .topic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 0 14px;
        .topic-name {
          color: #454e5c;
          font-family: Helvetica;
          font-size: 14.28px;
          font-style: normal;
          font-weight: 700;
          line-height: 29.749px; /* 208.333% */
          letter-spacing: 0.428px;
          display: flex;
          align-items: center;
        }

        .reload-btn {
          margin-left: 8px;
        }
      }
      .topic-line {
        padding-bottom: 12px;
      }
      .topic-left {
        display: flex;
      }

      .iframe-container {
        margin-top: -15px;
      }
      .feed-items {
        display: flex;
        padding: 6.6px 10px 10px 15px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #d5d5d5;
        background: #fff;
        box-shadow: 0px 2px 2px 0px rgba(221, 221, 221, 0.2);
        width: 95%;
        margin: 2px auto;

        .feed-date {
          color: #1d1d4b;

          /* Noto Sans/Subtitles */
          font-family: "Noto Sans";
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          .feed-title {
            color: #000;
            /* Noto Sans/Heading */
            font-family: "Noto Sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .video-feed-container {
        width: 556px;
        height: 369.767px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #fff;
        margin: 22px auto;
        z-index: 99999;
        overflow: hidden;

        .topic {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;
          padding: 0 14px;
          .topic-name {
            color: #454e5c;
            font-family: Helvetica;
            font-size: 14.28px;
            font-style: normal;
            font-weight: 700;
            line-height: 29.749px; /* 208.333% */
            letter-spacing: 0.428px;
            display: flex;
            align-items: center;
          }

          .reload-btn {
            margin-left: 8px;
          }
        }
        .topic-line {
          padding-bottom: 12px;
        }
        .topic-left {
          display: flex;
        }

        .video-items {
          margin: auto;
          width: 512.816px;
          height: 289.852px;
          border-radius: 5.398px;
        }
      }
    }
  }
}

// @media only screen and (max-height: 850px) {
//   .back-container {
//     height: 75vh !important;
//   }
//   .shared-feed-container {
//     width: 370px !important;
//     height: 42vh !important;
//   }
//   .feed-sharing-modal {
//     .ant-modal-body {
//       padding: 30px 55px 43px !important;
//     }
//   }
// }
