@use "../../../../style/scss/vars.scss" as vars;

.auth-modal-email-verification {
  &-container {
    color: vars.$secondary-0;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    padding-left: 40px;
    padding-top: 28px;
    position: relative;
    background-color: #ffffff;

    * {
      color: inherit;
      font-family: inherit;
    }

    h1 {
      color: vars.$secondary-0;
      font-size: 26px;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 16px;
    }

    > p {
      color: vars.$secondary-0;
      font-size: vars.$fs-md;
      font-weight: vars.$fw-normal;
      line-height: 150%;
      padding-bottom: 32px;
    }

    strong {
      font-weight: vars.$fw-semibold;
    }

    form {
      width: 100%;
      max-width: 440px;
      border: 1px solid #e1e4ed;
      box-shadow: 0px 1px 4px 0px #19213d14;
      padding: 22px 24px;
      border-radius: vars.$radius-lg;

      h3 {
        font-size: vars.$fs-md;
        font-weight: vars.$fw-semibold;
        margin-bottom: 20px;
        line-height: 24px;
      }

      .error-text {
        color: vars.$error-0;
        max-width: 392px;
        line-height: 21px;
        margin-bottom: 14px;
      }

      .otp-input {
        margin-bottom: 14px;
      }
    }

    .email-verification-footer {
      font-size: vars.$fs-md;
      margin-top: 30px;

      strong {
        font-weight: vars.$fw-medium;
        text-decoration: underline;
        cursor: pointer;
        display: block1;
      }
    }
  }
}
.auth-modal-password-reset-footer {
  color: var(--Parent-Website-Secondary, #1d1d4e);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 30px;
  position: absolute;
  bottom: 24px;

  strong {
    color: var(--Parent-Website-Secondary, #1d1d4e);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}

// .link:hover {
//   color: #1d1d4e !important;
// }
