@use "scss/vars";

nav.p-40 {
  padding: 40px 0px;
}

.standard-btn {
  // background: rgba(213, 213, 213, 0.5);
  background-color: #525a7a;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 12px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}
.standard-btn.disabled,
.standard-btn.disabled:hover {
  background-color: initial !important; /* Reset background */
  color: inherit !important; /* Reset color */
  cursor: not-allowed !important; /* Optional: Show as disabled */
}
.cancel-btn {
  background: transparent;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 15px;
}

.cancel-dialog-btn {
  background: transparent;
  padding: 6px 15px 4px 15px;
  font-size: 10px;
  line-height: 10px;
}

.standard-btn,
.cancel-btn,
.cancel-dialog-btn {
  border: 1px solid #5e5e5e !important;
  background-color: white;
  border-radius: 4px;
  margin: 5px;
  font-style: normal;
  font-weight: 900;
  color: #5e5e5e;
}

.standard-btn:hover {
  background-color: #f4f4f6 !important;
}
.standard-btn.disabled,
.standard-btn.disabled:hover {
  background-color: initial !important; /* Reset background */
  color: inherit !important; /* Reset color */
  cursor: not-allowed !important; /* Optional: Show as disabled */
}

.ant-space-vertical {
  gap: 0 !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.ant-radio {
  top: 0em !important;
}

.ant-radio-wrapper {
  font-size: 12px !important;
  line-height: 2 !important;
}

.tv-top-section .ant-radio-wrapper {
  margin-right: 0px !important;
  color: #757a8a !important;
  font-weight: 500 !important;
}

.tv-top-section .ant-radio-wrapper:hover {
  color: #5e616e !important;
  transition: 0.3s ease-out !important;
}

.tv-top-section .ant-radio-wrapper-checked {
  color: #5e616e !important;
  font-weight: 600 !important;
  border-bottom: 1px solid #5e616e !important;
}

span.ant-radio + * {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ant-input:focus {
  border-color: #5e5e5e !important;
  box-shadow: none !important;
}

.ant-radio-inner {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #000 !important;
}
.app-selector-drawer .ant-radio-inner {
  width: 0px !important;
  height: 0px !important;
  border: 1px solid #000 !important;
  opacity: 0;
}

.tv-top-section .ant-radio-inner {
  width: 0px !important;
  height: 0px !important;
  border: 1px solid #000 !important;
  opacity: 0;
}

.ant-radio-inner::after {
  width: 10px !important;
  height: 10px !important;
  margin-top: -5px !important;
  margin-left: -5px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #5e5e5e !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  // color: #5e5e5e !important;
  color: #5e616e;
}

.ant-radio-inner::after {
  background-color: #5e5e5e !important;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #5e5e5e !important;
  box-shadow: none !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: white !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: none !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-drawer-left {
  .ant-drawer-close {
    order: 2;
    margin-right: -8px;
  }
}

.m-l-10 {
  margin-left: 10px;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.ml-auto {
  margin-left: auto;
}

.sm-btn {
  width: 83px !important;
  height: 29px !important;
  font-size: 14px !important;
  padding: 5px !important;
  margin: 5px 5px;
}

.btn-lg {
  width: 154px !important;
}

.width-100 {
  width: 100% !important;
}

.error {
  color: #ea4545;
  margin: 5px 0px;
}

.flex {
  display: flex;
}

.link {
  cursor: pointer;
}

.tab-btn {
  background-color: #60cbff !important;

  &:active {
    background-color: blue !important;
  }

  &:hover {
    background-color: blue !important;
  }
}

.space-between {
  justify-content: space-between;
}

.rumble-content {
  overflow: hidden;

  // color: white !important;
  a {
    color: #7db4d6 !important;
  }
}

.disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.disabled:hover {
  background-color: none !important;
}

.disabled-dark {
  opacity: 0.5 !important;
  cursor: default !important;
}

// .disabled:hover {
//   background-color: white !important;
// }

.signup-message {
  text-align: center;
  color: #3cb7ff;
}

.p-y-10 {
  padding: 10px 0px;
}

.title-info {
  color: vars.$info-color;
  font-size: 1.2rem;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.img-bg-center {
  background-size: cover;
  background-position: center;
}

.couponInput {
  padding: 10px 10px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 10px;
  display: block;
}

.style-tag {
  width: 73px !important;
  border-radius: 5px !important;
  text-align: center !important;
}

.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
  display: none;
}

.ant-picker {
  padding: 0;
}

// .ant-input-affix-wrapper {
//   border: none !important;
// }
// .ant-input-affix-wrapper > input.ant-input {
//   padding: 8px 16px !important;
// }
.email-span {
  vertical-align: -webkit-baseline-middle;
  font-size: small;
  font-style: italic;
  font-weight: 400;
}

.ant-popover-placement-right {
  left: 75px !important;
  z-index: 11 !important;
}

.ant-popover-inner-content {
  color: white !important;
}

// .Cookies-Drawer {
//   z-index: 9999999;
// }
// .ant-modal {
//   width: auto !important;
// }

.antd-modal-container {
  position: relative;
  text-align: center;
  color: white;
}

.antd-modal-bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  cursor: pointer;
}

.antd-modal-top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  cursor: pointer;
  // color: #0c2c43;
  color: white;
}

.antd-modal-top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
  // color: #0c2c43;
  color: white;
}

@media screen and (min-width: 601px) {
  .ads-close-button {
    font-size: 4ev !important;
  }
}

.antd-modal-bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  cursor: pointer;
}

.antd-modal-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ant-modal-mask {
  background: transparent !important;
}

.payment-row {
  text-align: -webkit-center !important;
}

.payment-image {
  height: 400px !important;
  cursor: pointer;
}

.payment-image:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.news-feeds-description {
  cursor: pointer;

  img {
    width: -webkit-fill-available !important;
    border-radius: 30px !important;
    height: 160px !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    cursor: pointer;
  }

  a {
    color: #12507a !important;
  }

  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    float: none !important;
    width: -webkit-fill-available !important;
  }
}

.rumble-content {
  img {
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    cursor: pointer;
  }

  p {
    color: white;

    a {
      color: #12507a;
    }
  }

  a {
    color: #12507a;
  }
}

.ads-close-button {
  font-size: 20px !important;
}

.disable {
  opacity: 0.5 !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  min-width: 1920px !important;
  // min-height: 1100px !important;
  // height: -webkit-fill-available !important;
  // background-color: #cccccc;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  transform: none;
  overflow: hidden;
  background: rgba(29, 29, 75, 0.2);
  position: relative;
  // margin-left: 4px;
  // width: 190px !important;
  // background-color: #bbbbc8;
}

.react-grid-item-feed {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: rgba(137, 137, 161, 0.6) !important;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  // background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable {
  position: relative;
  background: transparent;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  // background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.video-js {
  // width: 100%;
  // height: 100%;
  background-color: black;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.5);

    .vjs-play-control {
      color: white;
    }

    .vjs-progress-control {
      color: white;
    }

    .vjs-volume-panel {
      color: white;
    }

    .vjs-control-text {
      color: white;
    }

    .vjs-live-display {
      color: white;
    }

    .vjs-icon-placeholder {
      color: white;
    }

    .vjs-control-text {
      color: white;
    }
  }
}

// @media screen and (min-width: 801px) {
//   .ads-close-button {
//     font-size: 1vw !important;
//   }
// }
// @media screen and (min-width: 500px) {
//   .ads-close-button {
//     font-size: 2vw !important;
//   }
// }

.cky-consent-container.cky-banner-bottom {
  z-index: 9999999;
}

.ant-input-clear-icon {
  display: none !important;
}

.ant-input-suffix {
  margin-left: 3px;
}

// span.ant-radio + * {
// }
// .app-selector-drawer {
//   .ant-radio:nth-of-type(2),
//   .ant-radio:nth-of-type(3) {
//     /* Your CSS styles here */
//     /* For example, changing background color */
//     padding-left: 12px !important;
//     /* You can add more styles as needed */
//   }
// }

.ant-switch {
  background-color: #9ca1a8 !important;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
