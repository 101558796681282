@use "../../../../style/scss/vars.scss" as vars;

.auth-checkout-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  a,
  label,
  strong,
  button,
  form,
  table,
  td,
  tr {
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
    font-family: inherit; // Inherit font family from the parent element
  }

  width: 100%;
  margin-inline: auto;
  max-width: 592px;
  min-height: 300px;
  max-height: 100vh;
  // padding: 10% 0;
  color: vars.$secondary-0;
  flex: 1;
  h1 {
    font-size: vars.$fs-2xl;
    font-weight: vars.$fw-bold;
    margin-bottom: 12px;
    color: inherit;
  }

  .checkout-error {
    margin-bottom: 24px;
    border: 1px solid vars.$error-0;
    padding: 12px 16px;
    border-radius: vars.$radius-md;
    background: rgba(255, 100, 78, 0.04);
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: vars.$fs-md;
    color: vars.$error-0;

    .image {
      height: 42px;
      width: 24px;
      min-width: 24px;
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .pricing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h3 {
      font-weight: vars.$fw-semibold;
      color: inherit;
    }

    .pricing-toggle {
      display: flex;
      align-items: center;
      gap: 12px;

      span {
        font-weight: vars.$fw-semibold;
      }
    }
  }

  form {
    border: none !important;
    box-shadow: none !important;
  }

  .checkout-form {
    .checkout-form__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 64px;

      .bill {
        position: relative;
        top: -3px;
        .bill__header {
          position: relative;
          top: -2.5px;
          label {
            font-weight: vars.$fw-medium;
            margin-bottom: 2px;
            display: block;
          }

          input {
            font-size: vars.$fs-sm;
          }
        }

        .bill__main {
          table {
            width: 100%;
            margin-top: 10px;
            strong {
              font-weight: vars.$fw-medium;
            }

            td {
              padding-bottom: 10px;
              span.period {
                color: vars.$text-gray;
              }
              &.metric {
                text-align: right;

                &.discount {
                  color: vars.$success;
                }
              }
            }

            tr.bill__result {
              td {
                padding-top: 10px;
                border-top: 1px dotted #c6c6c6;
              }
              strong {
                font-weight: vars.$fw-bolder;
              }
            }
          }
        }
      }
    }

    .checkout-form__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 43.5px;

      .terms {
        display: flex;
        align-items: center;
        gap: 8px;
        color: vars.$secondary-0;
      }

      span {
        font-size: vars.$fs-md;
      }
    }
  }
}
