.auth-modal-terms-condition {
  display: flex;
  gap: 6px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 14px;

  > *:nth-child(odd) {
    color: var(--dark-primary);
    text-decoration: underline;
    cursor: pointer;
  }
}
