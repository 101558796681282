@use "../../../../../style/scss/vars.scss" as vars;

.auth-layout-wrapper {
  display: grid;
  height: 100vh;
  overflow: hidden;
  grid-template-columns: 1fr;
  position: relative;
  font-family: vars.$ff-poppins !important;

  .left {
    background: vars.$secondary-0;
    position: sticky;
    top: 0;
    display: none;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);

    .left {
      display: block;
    }
  }

  .right {
    height: 100vh;
    overflow-y: auto;
    // display: grid;
    // place-items: center;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;

    .stepper-wrapper {
      margin-top: 50px;
      margin-bottom: 74px;
      width: 90%;
    }
  }

  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #c1c1c1 !important;
    }
  }

  input[type="checkbox"] {
    height: 16px;
    width: 16px;
  }

  .ant-checkbox-inner {
    border: 1px solid #c1c1c1;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 1px solid vars.$primary-0;
      background: vars.$primary-0;
    }
  }

  .error {
    color: vars.$error-0;

    input {
      color: vars.$secondary-0;
    }
  }

  .password-info-container {
    .title-text {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-block: 14px;
    }

    li {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .toc-container {
    li {
      display: flex;
      gap: 4px;
      align-items: center;

      label {
        display: flex;
        align-items: center;
        gap: 3px;

        a {
          text-decoration: underline;
        }
      }
    }
  }
  .privacy-policy {
    display: flex;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;

    > *:nth-child(odd) {
      color: var(--dark-primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
