.v2-logout-modal {
  text-align: center;

  .logout-modal-title {
    margin: 5px 0;
    font-weight: 500;
    font-size: 16px;
  }
  .logout-btn {
    background: rgba(213, 213, 213, 0.5);
    padding: 8px 30px;
    font-size: 14px;
    line-height: 12px;
    height: 30px;
    width: 87px;
    // display: flex;
    // align-items: center;
    justify-content: center;
    border: 1px solid #5e5e5e;
    border-radius: 5px;
    margin: 5px;
    font-style: normal;
    font-weight: 900;
    color: #5e5e5e;
    position:relative;
  }
}
