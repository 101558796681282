.auth-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(23, 24, 28, 0.6);
    // backdrop-filter: blur(4px);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    z-index: 99999;

    &.opened {
      opacity: 1;
      pointer-events: auto;
    }

    &.closed {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-container {
    position: fixed;
    top: 50%;
    left: 50%;
    border-radius: 16px;
    z-index: 1001;
    max-width: 1260px;
    width: 100%;
    height: 690px;
    margin-bottom: 32px;
    display: flex;
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    z-index: 999999;

    &.opened {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      pointer-events: auto;
    }

    &.closed {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.8);
      pointer-events: none;
    }
  }

  &-image {
    width: 580px;
    height: 690px;
    object-fit: contain;
  }

  &-close-button {
    position: absolute;
    top: 20px;
    right: 28px;
    z-index: 1002;
    cursor: pointer;
  }
}
