.ant-message {
  z-index: 9999999 !important;
}

.suspension-div {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 20%;

  img {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.authorization-form {
  border: 1px solid black !important;
}

.ant-switch-checked {
  background-color: #8989a1 !important;
}

.v2-edit-panel-single-words-container .ant-input-affix-wrapper {
  border: 1px solid #5e5e5e !important;
  padding: 0 2px 0 4px !important;
  font-size: 10px !important;

  .ant-input {
    padding: 4px 0;
  }
}

// .tabSearch
//   .search-container
//   .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
// .tabSearch .search-container .ant-input-affix-wrapper:hover {
//   border-color: #fdfdfd;
// }

.v2-edit-panel-single-words-container .ant-input-show-count-suffix {
  margin-top: auto !important;
  font-size: 8px;
  color: rgba(0, 0, 0, 0.35);
}

.ant-tooltip-inner {
  width: 100% !important;
  // height: 23px !important;
  min-height: 0px !important;
  flex-shrink: 0 !important;
  border-radius: 2px !important;
  border: 1px solid #ced0d4 !important;
  padding: 4px 8px !important;
  background: white !important;
  color: #000 !important;
  border: 1px solid #ced0d4 !important;
  // box-shadow: 0px 2px 2px 0px rgba(221, 221, 221, 0.2) !important;
}

.ant-tooltip-content {
  margin-left: -10px;
  margin-top: 10px;
  color: #000 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  /* text-align-last: center !important; */
}

// custom style for tooltip
.tooltip-container {
  .ant-tooltip-inner {
    padding: 10px !important;
    color: #000 !important;
  }
  &.dark {
    .ant-tooltip-inner {
      background: #515a67 !important;
      border: 1px solid #9ca1a8 !important;
      color: #fff !important;
    }
  }
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 0 !important;
}

.react-grid-item:hover .react-resizable-handle-s {
  visibility: visible !important;
}

.react-grid-item:hover .react-resizable-handle-se {
  visibility: visible !important;
}

.v2-authenticated-page {
  height: 100vh;
  width: 100%;
  display: flex;

  //Scroll Bar Style Start
  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }

  //Scroll Bar Style End

  .react-grid-item > .react-resizable-handle-se {
    visibility: hidden;
    background: url("../../images/sw-icon.svg") 50% / auto 90% no-repeat !important;
    height: 25px !important;
    width: 25px !important;
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 100000;
    // margin-bottom: 6px;
    margin-right: 0px;
  }

  // .react-grid-item>.react-resizable-handle-sw {
  //   // background: url("../../images/drag_icon.svg") !important;
  //   width: 10px !important;
  //   height: 10px !important;
  //   left: 4px !important;
  // }

  // .react-grid-item > .react-resizable-handle-s {
  //   border-radius: 0px 0px 4px 4px !important;
  //   background: #f4f4f6 !important;
  //   display: none;
  //   width: 99% !important;
  //   height: 20px !important;
  //   transform: none !important;
  //   left: 0 !important;
  //   margin-left: 4px !important;
  // }

  .react-grid-item > .react-resizable-handle-s {
    border-radius: 0px !important;
    background: url("../../images/ns-icon.svg") #f4f4f6 50% / auto 90% no-repeat;
    visibility: hidden;
    bottom: 4px !important;
    width: 99% !important;
    height: 25px !important;
    transform: none !important;
    left: 0 !important;
    margin-left: 4px !important;
    margin-right: 2px !important;
    margin-bottom: -6px !important;
    // margin-bottom: 0px !important;
  }

  // .react-grid-item>.react-resizable-handle-s {
  //   background: url("../../images/arrow-expand-bottom.svg") !important;
  //   width: 15px !important;
  //   height: 10px !important;
  //   transform: none !important;
  // }

  .react-grid-item > .react-resizable-handle-s.react-resizable-handle::after {
    display: none !important;
  }

  .react-grid-item > .react-resizable-handle::after {
    display: none !important;
  }

  .side-bar {
    background-color: white;
    background-repeat: repeat-y !important;
    width: 10%;
    // width: 40px;
    z-index: 11111 !important;
    height: 100%;
    // background: #66dbc4;
    color: black;
    margin: auto;
    // padding-left: 2px;
    .triangle-design {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      justify-content: right;
    }

    .triangle-design-icon {
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #ffffff;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: 230px;
    }

    .logo-area {
      // margin-top: 5px;
      height: 39px;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2px;
    }

    .sidebar-grid-icon {
      width: 21px;
      height: auto;

      img {
        margin: -1px;
      }
    }

    .sidebar-article-icon {
      width: 15px;
      height: auto;
      margin-left: -2px;
    }

    .sidebar-icon {
      width: 15px;
      height: auto;
    }

    .sidebar-grid-icon-white {
      width: 15px;
      height: auto;
      filter: brightness(0) invert(1);
    }

    .sidebar-article-icon-white {
      width: 15px;
      height: auto;
      filter: brightness(0) invert(1);
    }

    .sidebar-icon-white {
      width: 15px;
      height: auto;
      filter: brightness(0) invert(1);
    }

    &.collapsed {
      width: 40px;

      .side-nav-items {
        margin-top: 3rem;

        .full-width {
          padding-top: 65px;
          padding-left: 1px;
        }

        .side-nav-item {
          padding: 0;
          display: flex;
          margin: 20px 5px;
          align-items: center;
          justify-content: center;
        }

        .user-item {
          padding: 0;
          display: flex;
          margin: 10px 5px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ant-collapse-header {
    padding: 0 3px !important;
  }

  .main-panel {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    // background: repeating-linear-gradient(
    //   -45deg,
    //   rgba(29, 29, 75, 0.3),
    //   #fff 1px,
    //   #fff 10px,
    //   #fff 4px
    // );

    .dashboard {
      height: 100vh;
      width: 100%;
      overflow-x: auto;

      .rumble-column {
        width: 300px;
        height: 100%;
        border-right: 1px solid #dadada;

        .rumble-column-icon {
          height: 30px;
          filter: invert(1);
        }

        .rumble-column-img {
          height: 30px;
        }

        @media (max-width: 1280px) {
          width: 300px;
        }
      }
    }
  }
}

.v2-suite-grid-container {
  // margin: 4px 0px 0px 4px !important;
  height: 100%;
  -webkit-font-smoothing: auto !important;

  .ant-radio-wrapper {
    line-height: 2 !important;

    font-size: 10px !important;
    color: #5e5e5e !important;
    display: flex;
    align-items: center;
  }

  .v2-add-column-dialog .ant-radio-wrapper {
    font-weight: 500;
    color: #c6c9d2 !important;
  }

  .ant-radio-wrapper:hover {
    color: #fdfdfd !important;
  }

  .ant-radio-wrapper {
    font-weight: 500;
    color: red !important;
  }

  .tv-top-section .ant-radio-wrapper-checked {
    color: #fdfdfd !important;
    border-bottom: 1px solid #fff !important;
  }

  //Card Edit Panel
  .v2-column-header-option {
    background-color: white;
    overflow-y: auto;
    padding: 0 10px 10px 10px;
    height: calc(100% - 26px);
    border: 1px solid #d5d5d5;
    font-size: 12px;
    // margin-right: 10px;
    // -ms-overflow-style: none;
    // /* IE and Edge */
    // scrollbar-width: none;
    margin-left: 4px;
    margin-top: 2px;

    .ant-btn-circle.ant-btn-sm {
      min-width: 18px !important;
    }

    .ant-btn-icon-only.ant-btn-sm {
      width: 18px !important;
      height: 18px !important;
    }

    .v2-column-header-option-close-btn {
      height: 10px;
      width: 10px;

      .v2-column-header-option-close-btn-icon {
        color: gray;
        height: 10px;
        width: 10px;
      }
    }

    .ant-collapse-content {
      height: 100% !important;

      .ant-collapse-content-box {
        padding: 0 7px !important;
        // overflow: scroll !important;
        font-size: 10px !important;
        // height: 200px;
      }

      .ant-collapse-content-box::-webkit-scrollbar {
        display: none;
      }
    }

    .v2-edit-panel {
      overflow-y: auto;

      .red-text {
        color: #ff644e;
        font-weight: 500;
        line-height: 14px;
        margin: 5px 0;
      }

      .ok-dialog-btn {
        background: transparent;
        border: 1px solid #5e5e5e;
        border-radius: 5px;
        font-weight: 600;
        margin: 5px;
        padding: 6px 20px 4px 20px;
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
        line-height: 10px;
        color: #5e5e5e;
      }

      .disabled {
        opacity: 0.1;
        pointer-events: none;
      }

      .v2-edit-panel-collapse {
        font-size: 12px;
        background-color: white !important;
        border: none !important;
        border-radius: 0px !important;
      }

      .v2-edit-panel-select {
        height: auto;

        .edit-layout-label-seetings {
          font-size: 10px;
          padding: 2px 2px;
        }

        .edit-layout-label-seetings:hover {
          border-radius: 2px;
          background: #e9e9ed;
        }

        .user-item {
          label {
            width: 100%;
          }
          .ant-radio-wrapper {
            margin-right: 0px;
          }

          span.ant-radio + * {
            padding-right: 0px;
            width: 95%;
          }
        }
      }
    }
  }

  .v2-edit-panel-collapse1 {
    font-size: 12px;
    font-weight: 500;
    color: #5e5e5e;
    background-color: white !important;
    border: none !important;
    border-radius: 0px !important;
  }

  .v2-edit-panel-keyword-div {
    font-size: 10px;
  }

  .div-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .v2-edit-panel-tag-div {
    position: relative;
    display: inline-block;
    margin: 0 5px 5px 0;

    .v2-edit-panel-tag {
      font-size: 10px;
      margin: 7px 5px 0 0;
      color: #000000;
    }

    .v2-edit-panel-tag-icon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .v2-edit-panel-grouped-tag-div {
    display: inline-block;
    margin: 0 5px 5px 0;

    .v2-edit-panel-grouped-tag {
      font-size: 10px;
      margin: 7px 5px 0 0;
      border-radius: 5px;
      border: 0.25px solid #5e5e5e;
      color: #5e5e5e;
    }
  }

  .v2-edit-panel-delete-topic {
    color: #ff644e;
    text-align: right;
  }

  .v2-edit-panel-upgrade-note {
    background: #ff644e;
    color: #ffffff !important;
    display: inline-block;
    padding: 0 2px;
    margin-top: 3px;
    cursor: pointer;
  }

  .v2-edit-panel-limit-note {
    background: #ff644e;
    color: #ffffff;
    display: inline-block;
    padding: 0 2px;
    margin: 10px 0 5px 0;
    font-family: IBM Plex Mono;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
  }

  .v2-edit-panel-info-icon {
    cursor: pointer;
    margin: auto 0;
  }

  .settings-divider {
    height: 1px;
    background: #f8f8f9;
    width: 98%;
    margin: 5px auto;
  }

  .v2-edit-panel-setting-title-div {
    display: flex;
    margin-bottom: 7px;
    color: #000000;

    .v2-edit-panel-setting-icon {
      margin: auto 0px auto 4px;
    }

    .v2-edit-panel-setting-title {
      margin: auto 0;
      font-weight: 500;
    }
  }

  .v2-edit-panel-single-words-div {
    display: flex;
    justify-content: space-between;
    margin: 7px 0;
  }

  .v2-edit-panel-alert-settings-div {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    .v2-edit-panel-alert-settings-item {
      display: flex;
    }
  }

  .v2-column-header-option {
    /* Hide scrollbar for Webkit-based browsers */
    -webkit-overflow-scrolling: touch; /* Optional, for smooth scrolling on iOS */
    overflow: -moz-hidden-unscrollable; /* For Firefox */
    overflow: -webkit-paged-x; /* For Webkit browsers */
    overflow: auto !important; /* General overflow hidden */
  }

  /* Hide scrollbar for Firefox */
  .v2-column-header-option {
    scrollbar-width: none; /* Hide the scrollbar */
  }
  .view-chart-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    iframe {
      border: 1px solid #d5d5d5 !important;
      height: 100% !important;
    }

    .view-chart {
      flex-grow: 1;
      // background: rgba(29, 29, 75, 0.3) !important;
      border: 1px solid #d5d5d5;
      margin-top: 2px;
      margin-left: 4px !important;

      border-radius: 0 0 4px 4px !important;
      background: #ffffff !important;

      .v2-column-header-option {
        height: calc(100% - 2px) !important;
      }
    }

    .calender-chart {
      flex-grow: 1;
      // background: rgba(29, 29, 75, 0.3) !important;
      border: 1px solid #d5d5d5;
      margin-top: 2px;
      margin-left: 4px !important;
      border-radius: 4px !important;
      background: #ffffff !important;
    }

    .investing-credit {
      padding-left: 5px;
      padding-bottom: 15px;
      margin-left: 4px !important;

      // padding-top: 5px;
      border: 1px solid #d5d5d5;
      font-size: 11px;
      color: #384251;
      text-decoration: none;
      background: white;

      a {
        font-size: 11px;
        color: #06529d;
        font-weight: bold;
      }
    }

    .market-overview-widget-container,
    .stock-screener-widget-container,
    .forexcrossrates-widget-container {
      height: calc(100% - 75px) !important;
      width: calc(100% - 4px) !important;
      margin-left: 4px;
      margin-top: 2px;
      // border: 1px solid #d5d5d5;
    }

    .widget-container {
      width: calc(100% - 4px) !important;
      margin-left: 4px;
      margin-top: 2px;
      // border: 1px solid #d5d5d5;

      #financialjuice-eco-widget-container {
        height: 100%;
      }

      iframe {
        // border-bottom: 2px solid white !important;
      }

      // .tv-embed-widget-wrapper__body {
      //   background: red;
      // }

      .tradingview-widget-copyright {
        line-height: 17px !important;
        width: calc(100% - -2px) !important;
        border-bottom: 1px solid #d9dadb;
        border-left: 1px solid #d9dadb;
        border-right: 1px solid #d9dadb;

        // text-align: right !important;
        .blue-text {
          color: #000000 !important;
        }
      }
    }
  }

  .v2-suite-column {
    overflow-y: scroll !important;
    height: 100% !important;
    margin: 0px 0px 0px 4px !important;

    font-size: smaller !important;

    // height: -webkit-fill-available !important;

    // background: rgba(29, 29, 75, 0.3) !important;
    background: #ffffff !important;

    // width: auto !important;

    // margin: 0px 0px 0px 4px !important;

    // box-shadow: 0px 2px 3px rgba(146, 146, 146, 0.42) !important;

    /* CSS */
    .loadmore-button-container {
      text-align-last: center;
      position: relative;

      .button {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        // margin-top: 5px;
        // margin-bottom: 5px;
        align-items: center;
        appearance: none;
        background-color: #fff;
        border-radius: 40px;
        border-style: none;
        box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
          rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
        box-sizing: border-box;
        color: #3c4043;
        cursor: pointer;
        display: inline-flex;
        fill: currentcolor;
        font-size: 14px;
        font-weight: 500;
        width: auto;
        height: 35px;
        justify-content: center;
        letter-spacing: 0.25px;
        line-height: normal;
        overflow: visible;
        padding: 2px 24px;
        text-align: center;
        text-transform: none;
        // transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
        //   opacity 15ms linear 30ms,
        //   transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        will-change: transform, opacity;
        transition: all 0.3s ease-out;
        // transition: max-width 4000ms ease-in-out;
        // -webkit-transition: max-width 4000ms ease-in-out;
        // -moz-transition: max-width 4000ms ease-in-out;
      }

      // .button:focus {
      //   box-shadow: #66dbc4 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      //     rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #66dbc4 0 -3px 0 inset;
      // }

      .button:hover {
        background-color: #66dbc4;
        color: white;
        max-width: 100%;
        top: -52px;
        // transform: translateY(-2px);
      }

      .button:active {
        box-shadow: #66dbc4 0 3px 7px inset;
        top: -48px;
        // transform: translateY(2px);
        transition: background-color 0.3s ease, color 0.3s ease;
      }

      .button:after {
        box-shadow: #66dbc4 0 3px 7px inset;
        top: -48px;
        // transform: translateY(2px);
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }

    .ant-alert-warning {
      background-color: white !important;
      border: white;
    }

    .v2-suite-column-card-item-reuters,
    .v2-suite-column-card-item {
      opacity: 1 !important;
      color: #000000;
      width: auto !important;
      background-color: #ffffff;
      // padding: 5px 10px 8px 15px !important;
      margin: 2px 0px !important;
      border-radius: 4px !important;
      border: 1px solid #d5d5d5;
      box-shadow: 0px 2px 2px 0px rgba(221, 221, 221, 0.2) !important;

      .v2-suite-column-main-title {
        font-family: "Noto Sans", sans-serif !important;
        color: #000000;
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 2px;
        text-transform: uppercase;
      }

      .time-label {
        font-size: 10px;
        line-height: 10px;
        padding-top: 5px;
        margin-bottom: 2px;

        font-weight: 500;
        color: #1d1d4b;
      }

      .share-icon-div {
        text-align: right;
      }

      .breaking-tag {
        color: #fff;
        text-align: center;
        font-size: 9px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
        background-color: #ff644e;
        cursor: pointer;
        width: 55px;
        border-radius: 0px;
        padding: 1px 4px 2px 4px;
        margin-right: 10px;
        margin-top: 2px;
      }
    }

    .card-highlight-due-to-keyword {
      background-color: #fafafb !important;
      border: 2px solid #000 !important;
    }

    .v2-suite-column-card-item {
      opacity: 1 !important;

      word-break: break-word;

      // border-width: 0px !important;
      // border-style: solid !important;
      width: auto !important;
      box-shadow: 0px 2px 2px 0px rgba(221, 221, 221, 0.2) !important;
      border: 1px solid #d5d5d5;
      border-radius: 4px !important;
      background-color: #fff;
      // padding: 6px 10px 8px 15px !important;
      margin: 2px 0px !important;

      .piq-feed-partner-name {
        text-transform: capitalize;
      }

      .v2-suite-column-title {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
      }

      .v2-suite-column-main-title {
        font-family: "Noto Sans", sans-serif !important;

        color: #000000;
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 2px;
      }

      .twitter-user {
        font-size: 11px;
        line-height: 14px;
        color: #000000;
        font-weight: 800;
      }

      .v2-suite-column-card-title {
        // // color: #fff !important;
        // font-size: 11px;
        // line-height: 14px;
        // margin-bottom: 3px;
        // // color: #5e5e5e !important; //Standard Theme
        // color: #1d1d4b !important;
        font-size: 10px;

        font-style: normal;

        font-weight: 500;

        line-height: 13px;
        color: #1d1d4b !important;
      }

      .retweeted-user {
        font-weight: 400;
        font-size: 9px;
        line-height: 12px;
        margin-top: 3px;
        color: #000000;
        margin-top: 3px;
      }

      .rumble-content {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        cursor: pointer;
        margin: 3px 0;
        margin-top: 3px;
        margin-bottom: 3px;
        color: #000000; // STANDARD THEMES
      }

      .time-label {
        font-size: 10px;
        line-height: 10px;
        font-weight: 500;
        color: #1d1d4b;
        padding-top: 5px;
        margin-bottom: 2px;
      }

      .news-feeds-description {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 3px;
        overflow-wrap: break-word;
      }

      .share-icon-div {
        text-align: right;
      }
    }

    .v2-suite-column-skeleton {
      margin-left: 4px !important;
      margin-right: 2px !important;
      margin-top: 10px;
      color: #fff !important;
    }
  }

  .v2-column-container {
    border: 1px solid #e9dada;
    // height: -webkit-fill-available;
  }

  .v2-suite-column-card-item-img {
    max-height: 150px;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 2px;
  }

  .v2-column-header {
    background: #f4f4f6;
    height: 20px !important;
    // margin-top: 4px;
    margin: 4px 0px 0px 4px !important;
    border-bottom: 2px solid #d5d5d5 !important;
    border-top: 1px solid #d5d5d5 !important;
    border-left: 1px solid #d5d5d5 !important;
    border-right: 1px solid #d5d5d5 !important;

    display: flex;
    // border: 1px solid red;
    padding: 0 10px;

    .filler {
      flex: 1 1 100%;
      height: 100%;
      width: 100%;
    }

    .title-container {
      display: flex;
      align-items: center;
      // gap: 5px;
      width: 80%;
    }

    .ant-row {
      color: black !important;
      border-bottom: 1px !important;
      row-gap: 0px !important;
      height: 20px;
      flex-flow: row;
      .ant-col {
        height: 20px !important;
      }
    }
    .feed_initialization {
      .ant-col-20 {
        display: block;
        flex: 0 0 71.333333% !important;
        max-width: 71.333333% !important;
      }

      span {
        display: flow;
        /* width: 12ch; */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


    .action-icons{
      flex:1;

      & > *{
        width:11px;
      }
    }


    .v2-column-header-option-icon {
      cursor: pointer;
      display: inline-block;
    }

    .v2-column-header-option-icon.active {
      width: 20px;
      padding: 0px;
    }
   
    .v2-column-header-reload-icon {
      // height: 8px;
      margin-left: 5px;
      // width: 8px;
      width: 18px !important;
      margin-right: 4px;

      svg {
        font-weight: 900 !important;
      }

      img {
        height: 8px;
        width: 8px;
      }
    }

    .v2-column-header-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      overflow: hidden;
      white-space: nowrap;
      letter-spacing: 0.6px;
      display: block;
      text-overflow: ellipsis;
    }

    img {
      filter: brightness(0) invert(0);

      width: 100%;
      height: 100%;
    }
  }

  .v2-video-column-header {
    background: #f4f4f6;
    height: 20px !important;
    // margin-top: 4px;
    margin: 0 !important;
    border-bottom: 2px solid #d5d5d5 !important;

    .ant-row {
      color: black !important;
      border-bottom: 1px !important;
      row-gap: 0px !important;
      height: 20px;

      .ant-col {
        height: 20px !important;
      }
    }

    .v2-column-header-option-icon {
      // width: 18px;
      // aspect-ratio: 1/1;
      // border: 1px solid red;
      aspect-ratio: 1/1;
      cursor: pointer;
    }

    // .ant-col-20 {
    //   display: block;
    //   flex: 0 0 83.333333% !important;
    //   max-width: 83.333333% !important;
    // }

    // .v2-column-header-filter-icon {
    //   width: 10px;
    //   cursor: pointer;
    //   margin: 0 4px;
    // }

    .v2-column-header-reload-icon {
      // height: 8px;
      margin-left: 0px;
      width: 22px !important;

      svg {
        font-weight: 900 !important;
      }

      // img {
      //   // height: 8px;
      //   // margin-left: 5px;
      // }
    }

    .v2-column-header-text {
      display: flex;
      padding-top: 3px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      letter-spacing: 0.6px;
    }

    img {
      filter: brightness(0) invert(0);
    }
  }
}

.v2-suite-column::-webkit-scrollbar {
  display: none;
}

.ant-drawer-header {
  padding: 34px 0 20px 0 !important;
}

.ant-drawer-body {
  padding: 0 5px !important;
  margin: 10px !important;
}

.ant-drawer-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.4px !important;
  color: #5e5e5e !important;
}

.add-icon-div {
  position: fixed;
  background: #fff;
  // background: transparent;
  padding: 14px;
  height: 50px;
  width: 50px;
  right: 70px;
  bottom: 0;
  border-radius: 50%;
  margin-bottom: 35px;
  // box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background: #66dbc4;
  }

  .add-icon {
    font-size: 30px;
    color: #1d1d4b33;
  }
}

.overlay-blur {
  position: absolute;
  top: 26px;
  bottom: 0;
  // left: 4px;
  right: 0;
  height: 94%;
  width: calc(100% - 4px) !important;
  background-color: white !important;
  text-align-last: center !important;
  border-radius: 4px !important;
  border: 1px solid #d5d5d5;
  // backdrop-filter: grayscale(100%);
  // background: repeating-linear-gradient(
  //   -45deg,
  //   #7d7d8d,
  //   rgba(29, 29, 75, 0.4) 1px,
  //   rgba(29, 29, 75, 0.4) 10px,
  //   #7d7d8d 4px
  // );
  // opacity: 0.8;
  cursor: pointer;

  .overlay-reuter-contain {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: hidden;
  }
}

.overlay-blur-reuters {
  position: absolute;
  top: 25px;
  bottom: 0;
  right: 0;
  width: calc(100% - 4px);
  background-color: white !important;
  text-align-last: center !important;
  border-radius: 4px !important;
  border: 1px solid #d5d5d5;
  // backdrop-filter: grayscale(100%);
  // background: repeating-linear-gradient(
  //   -45deg,
  //   #7d7d8d,
  //   rgba(29, 29, 75, 0.4) 1px,
  //   rgba(29, 29, 75, 0.4) 10px,
  //   #7d7d8d 4px
  // );
  // opacity: 0.8;
  cursor: pointer;

  .overlay-reuter-contain {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: hidden;
  }
}

.lock-log {
  margin-top: 30px;
  transform: translate(-50%, -50%);
  color: #000000 !important;
  margin-left: 190px;
  margin-top: 50px;
  width: 100%;
  height: 62px;
}

.lock-text {
  position: absolute;
  /* transform: translate(-50%, -50%); */
  height: auto;
  text-align: -webkit-center;
  color: #000000 !important;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  width: 100%;

  span {
    font-weight: 900;
  }
}

.lock-icon {
  // position: absolute;
  /* transform: translate(-50%, -50%); */
  height: 25px;
  color: #000000 !important;
  margin-top: 40px;
}

.lock-signup {
  // position: absolute;
  /* transform: translate(-50%, -50%); */
  height: auto;
  text-align: -webkit-center;
  color: #000000 !important;
  font-size: 11px;
  margin-top: 6px;
  width: 100%;

  span {
    font-weight: 900;
  }

  .signup-text {
    font-weight: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.video-player {
  display: flex;
  flex-flow: column;
  height: 100%;

  .video-player-div {
    height: 100%;
    background: transparent !important;
    // margin-top: 2px;
    height: calc(100% - 6px) !important;
    width: calc(100% - 4px) !important;

    .v2-column-header-option {
      height: calc(100% - 20px) !important;
      width: 100% !important;
    }
  }
}

.blur-effect {
  // opacity: 0.5;
  pointer-events: none;
}

.v2-video-contatiner {
  background: #000000 !important;
  border: 1px solid #d5d5d5;
  // margin-top: 2px;
  margin: 2px 0 0 4px !important;
  border-radius: 0 0 4px 4px !important;
  // background: rgba(29, 29, 75, 0.3) !important;
  // border-radius: 8px 8px 0px 0px !important;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 20px);
}

.video-js {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.dashboard-grid {
  width: 100%;
  height: 100%;
  position: relative;

  .add-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .add-btn:hover {
    cursor: pointer;
  }
}

.video-edit-panel {
  padding: 10px;
  height: 100%;
  overflow-y: scroll;
  font-size: 12px;
}

//Edit Save Dialog Style Start
.edit-save-dialog {
  position: absolute;
  width: 85%;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0px 2px 3px rgba(94, 94, 94, 0.42);
  border-radius: 4px;

  .save-dialog-btn {
    background: rgba(213, 213, 213, 0.5);
    border: 1px solid #5e5e5e;
    border-radius: 5px;
    font-weight: 600;
    padding: 6px 15px 4px 15px;
    margin: 5px;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 10px;
    color: #5e5e5e;
  }
}

//Save Dialog Style End

// Article List Style
.article-panel {
  .ant-drawer-header {
    padding: 34px 0 20px 0 !important;
    border-bottom: 0 solid #f0f0f0 !important;
  }

  .ant-drawer-body {
    padding: 0 40px 24px 40px;
  }
}

.ant-modal {
  position: sticky !important;
}

// Add Card/App Selector Dialog Box
.v2-add-column-dialog {
  // top: 7% !important;
  // left: -15% !important;
  --padding: 30px;
  width: calc(100% - 50px) !important;
  // max-width: 965px !important;
  // left: 6% !important;
  // height: 95vh !important;
  // max-height: 909px !important;
  container-name: "app-selector-modal";

  .ant-modal-content {
    position: relative;
    align-items: center !important;
    // border: 2px solid #d5d5d5 !important;
    border-radius: 4px !important;
    display: flex;
    flex-direction: column;
    // height: 100%;

    .ant-modal-close-x {
      margin-right: var(--padding);
      &,
      img {
        width: 10px;
        height: 10px;
        margin-top: 19px;
        margin-left: -1px;
      }
    }

    .ant-modal-header {
      padding: var(--padding) var(--padding) 30px var(--padding);
      .ant-modal-title {
        font-size: 24px !important;
        font-weight: 500 !important;
        letter-spacing: 0.4px !important;
      }
    }
  }

  .ant-modal-body {
    --app-selector-list-container-height: 250px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    // padding: 0 var(--padding) 0 var(--padding) !important;
    padding: 40px 40px 40px;

    position: relative;
    background-color: white;
    border-radius: 16px;
    // height: 909px;
    // width: 957px;
    // max-height: 80vh;
    // max-width: 90vw;
    height: 100%;
    width: 100%;

    .app-selector-button-group {
      // right: unset !important;
      bottom: 0;
      // padding: 10px 20px 20px;
      // border: 1px solid #3434591a;
      border-top: none;
      // bottom: 34px !important;
      // width: calc(100% - var(--padding) * 2) !important;
      margin-inline: auto !important;
      span {
        span {
          label {
            width: 100%;
          }
        }
      }
    }

    .ant-tabs-tab {
      height: 32px;
      border: 1px solid #b8bbc7;
      font-weight: 500 !important;
      background: white !important;

      // &:hover {
      //   background: #f1f1fd !important;
      //   color: #5e616e !important;
      // }

      & + .ant-tabs-tab {
        margin: 0;
        margin-left: 0 !important;
        border-left: none;
      }

      & + .ant-tabs-tab:hover {
        background: #f1f1f4 !important;
        color: #5e616e !important;
        .ant-tabs-tab-btn {
          color: #5e616e !important;
        }
      }

      .ant-tabs-tab-btn {
        color: #5e616e;
        font-size: 12px !important;
        text-shadow: none !important;
      }

      .ant-tabs-tab-btn[aria-controls="rc-tabs-0-panel-1"],
      .ant-tabs-tab-btn[aria-controls="rc-tabs-0-panel-2"] {
        font-size: 14px !important;
      }

      // .ant-tabs-tab-btn[aria-selected="true"] {
      //   color: #fdfdfd !important; /* This rule might conflict, adjust below */
      // }
    }

    .ant-tabs-tab-active {
      background: #525a7a !important; /* Active tab background */
      // color: #fdfdfd !important; /* Ensure active tab text color is white */

      .ant-tabs-tab-btn {
        color: #fdfdfd !important; /* Specifically target active tab button text */
      }
    }

    .ant-tabs-tab-active:hover {
      background: #f1f1f4 !important;
      transition: 0.3s ease-in !important;
      /* Active tab background */
      // color: #fdfdfd !important; /* Ensure active tab text color is white */

      .ant-tabs-tab-btn {
        color: #5e616e !important; /* Specifically target active tab button text */
      }
    }

    .ant-tabs-tab:hover {
      background: #f1f1f4 !important; /* Active tab background */
      transition: 0.3s ease-in !important;
      // color: #fdfdfd !important; /* Ensure active tab text color is white */

      .ant-tabs-tab-btn {
        color: #5e616e !important; /* Specifically target active tab button text */
      }
    }

    .ant-tabs-content-holder {
      // height: 145px;
      overflow-y: hidden;
      overflow-x: hidden;

      .ant-tabs-content-holder::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
    }

    .app-selector-list-container {
      .ant-tabs-tab {
        padding: 6px 12px !important;
      }
      .ant-tabs-content-holder {
        // height: 40% !important;
        // max-height: var(--app-selector-list-container-height) !important;
        // border: 1px solid red;

        flex: 1;
        padding: 10px 20px 0;

        padding-right: 0;

        &,
        .ant-tabs-content {
          // height: 100%;
          // max-height: 430px;
          padding-top: 22px;
        }

        .ant-tabs-content {
          & > div {
            height: 100%;
            max-height: 415px;
            // overflow: auto;
            // padding-bottom: 20px;
          }
        }
      }
    }

    .standard-btn {
      background-color: #525a7a;
      color: #fdfdfd;
      display: flex;
      width: 63px;
      height: 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin: 0;
      font-weight: 500;
      border-radius: 4px;
    }

    .cancel-btn {
      display: flex;
      width: 127;
      height: 32px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      margin: 0;
      font-weight: 500;
      border: 1px solid #525a7a !important;
      color: #525a7a !important;
      // border: none !important;
    }

    .cancel-btn:hover {
      background-color: #f1f1f4 !important;
    }

    .standard-btn:hover {
      background-color: #667299 !important;
      color: #fdfdfd !important;
    }
    .standard-btn.disabled,
    .standard-btn.disabled:hover {
      // background-color: initial !important; /* Reset background */
      // color: inherit !important; /* Reset color */
      background-color: #525a7a !important;
      color: #fdfdfd !important;
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }
    .cancel-btn.disabled,
    .cancel-btn.disabled:hover {
      // background-color: initial !important; /* Reset background */
      // color: inherit !important; /* Reset color */
      background-color: #ffffff !important;
      color: #525a7a !important;
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }
  }

  .ant-tabs-top {
    position: relative !important;
  }

  // .ant-tabs-nav {
  //   margin: 0 0 15px 0px !important;
  //   // border-top: #f8f8f9 solid 1px !important;
  //   // border-bottom: #f8f8f9 solid 1px !important;
  //   // padding-top: 15px;
  //   padding-bottom: 15px;
  // }
  .main-topic-app-selector-tabs {
    .ant-tabs-top {
      border: 1px solid #c6c9d2;
      border-bottom: 0;
      & > .ant-tabs-nav {
        width: 1180px !important;
        margin: 0 !important;
        padding-bottom: 0 !important;

        .ant-tabs-tab {
          height: 36px;
          padding: 8px 12px !important;
        }

        .ant-tabs-nav-list {
          border-left: none !important;
          border-top: none !important;
        }

        &:before {
          border-bottom: none !important;
        }
      }
    }
  }

  .app-selector-title {
    font-weight: 400;
    margin-bottom: 12px;
    margin-top: 11px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .app-selector-drawer-box {
    display: flex;
    flex-direction: column;
    height: 90%;
    // overflow: hidden;
    .tile-select-name {
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      margin-top: 10px;
    }

    .app-selector-drawer-main-img {
      height: 24px;
      width: 24px;
    }
    .filter-app-selector-img {
      filter: brightness(0.76) contrast(1.18) !important;
    }
  }

  .ant-radio-button-wrapper {
    line-height: 1 !important;
  }

  .ant-radio-button-wrapper:focus-within {
    box-shadow: none;
  }

  .app-select-container {
    // padding: 8px;
    background: #fdfdfd;
    padding: 0 8px;
    border-radius: 2px !important;
    line-height: 20px;
    width: 220px;
    height: 34px !important;
    border: 1px solid #757a8a !important;

    svg {
      margin: auto 0;
    }

    &.ant-radio-button-wrapper-disabled {
      &:not(.active) {
        background-color: #ffffff !important;
        opacity: 0.5;
        border: 1px solid #a9adbc !important;
        color: #5e616e !important;

        img {
          filter: saturate(0);
        }
      }
      &:hover,
      .app-select-label:hover {
        // color: #ababb5 !important;
      }

      &.active {
        background: #e2e4e9 !important;
        border: 1px solid #757a8a !important;
        // opacity: 0.7;
        color: #17181c;

        // &:hover,
        // .app-select-label:hover {
        //   color: #000 !important;
        // }
      }
    }

    // &.ant-radio-button-wrapper-disabled.restrict {
    //   .app-select-label {
    //     background-color: transparent !important;
    //     color: black !important;
    //   }
    // }

    &:hover {
      background: #f2f2f4;
      color: black;
    }
  }

  .app-select-label {
    // color: #17181c;
    margin: auto 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    padding: 8px 0;
  }

  // .app-select-label:hover {
  //   border-radius: 2px;
  //   color: black;
  // }

  .app-select-icon-container {
    border-radius: 4px;
    height: 16px;
    width: 16px;
    // margin: auto 10px auto 0;
    background: #fff;
    margin: auto 0;

    .app-select-icon {
      // width: 18px;
      // height: 18px;
      height: 100%;
      width: 100%;
      border-radius: 1.78px;
      border: 0.36px solid #d4d6dd;
      display: block;
      object-fit: contain;
    }
  }

  .ant-modal-header {
    border-bottom: none;
    // font-size: 24px !important;
  }

  .ant-modal-footer {
    border-top: 0 solid #f0f0f0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #e2e4e9 !important;
    border-color: #17181c !important;
    border-style: dashed !important;
    border-width: 1px !important;
  }
  // .ant-radio-group-solid
  //   .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  //   color: white;
  //   background: white;
  // }

  // .ant-radio-button-wrapper-disabled:first-child {
  //   background-color: white;

  //   .app-select-label {
  //     background-color: #b2b5be;
  //     color: white;
  //   }
  // }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0 !important;
  }

  .tab-category-container {
    .ant-tabs-content-holder {
      padding: 0 !important;
    }

    .ant-tabs-top {
      .ant-tabs-tab {
        padding: 0 !important;
      }
      & > .ant-tabs-content-holder {
        display: none;
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border: none;
        text-transform: capitalize;
        margin-right: 19px;
        height: 28px;
        padding-bottom: 10px;

        &:hover {
          background: none !important;
          .ant-tabs-tab-btn {
            color: #5e616e;
          }
        }

        .ant-tabs-tab-btn {
          color: #757a8a;
        }
      }
      .ant-tabs-tab-active {
        background: none !important;
        border-bottom: 1px solid #000000;

        .ant-tabs-tab-btn {
          color: #5e616e !important;
          font-weight: 600 !important;
        }
      }
    }

    &.partner-feed {
      .filter-container {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .filter-icon {
        height: 26px;
        width: 26px;
        border: 1px solid #9ca1a8;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed !important;
        }

        &:hover {
          background: #f4f4f6;
        }

        &.active {
          background: #d6d6de;
          border-color: #85859b;
        }
      }
    }

    .selected-filters-container {
      display: flex;
      gap: 6px;
      margin-top: 10px;
      // margin-bottom: 20px;
      justify-content: end;
      width: 60%;

      .selected-filter-item {
        padding: 2px 6px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        gap: 6px;
        color: #17181c;
        background: #f6f7f8;
        border: 1px solid #a9adbc;
        text-transform: capitalize;
        font-size: 12px;
        height: 20px;

        svg {
          cursor: pointer;
        }

        // &:hover {
        //   background: #c6c6cc;
        // }
      }
    }
  }
}

// register dialog sidebar
.ant-drawer-content-wrapper {
  left: 40px !important;
}

.v2-add-column-dialog .app-selector-drawer {
  border: 1px solid #c6c9d2;
  border-top: none;
  // padding: 10px 0 10px 0;
  margin-bottom: 12px;

  .ant-radio-group {
    width: 1180px;
    height: 85px;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    color: white !important;
  }

  .ant-radio-wrapper {
    display: flex !important;
    flex-direction: column;

    // line-height: 10px !important;
    line-height: 2 !important;
    margin-right: 0;

    .ant-radio {
      order: 2;
      align-self: center;

      & + * {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.ant-radio-wrapper:hover {
  font-weight: 500;
  color: #fdfdfd !important;
  .tile-select-name {
    color: #5e616e !important;
    transition: 0.3s ease-in !important;
  }
  .app-selector-drawer-main-img {
    filter: brightness(0.6) !important;
    transition: 0.3s ease-in !important;
  }
}

div.ant-tabs-tab {
  border-radius: 0px !important;
}

.ant-radio-wrapper .tile-select-name {
  color: #a9adbc !important;
}
.ant-radio-wrapper-checked .tile-select-name {
  color: #5e616e !important;
}

.ant-radio-wrapper-checked .app-selector-drawer-main-img {
  filter: brightness(0.6) !important;
}

.main-panel {
  background: none;
}

.suite-grid-layout {
  // background-size: 100% 100%;
  // height: 100% !important;
  min-height: 1100px;
  width: 823%;
  background-color: #fafafa;
  // background-image: url("../../images/bg-diagonal-lines-light-theme.svg");
  background-image: url("../../images/grid-lines-light-extended.svg");

  background-repeat: repeat;
}

// .ant-drawer-left>.ant-drawer-content-wrapper {
//   bottom: auto !important;
// }

/* --------- Responsive ---------- */
// @media only screen and (max-width: 450px) {
//   .v2-add-column-dialog {
//     top: 0 !important;
//     left: 0 !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 0 !important;
//   }
// }

// @media only screen and (min-width: 450px) {
//   .v2-add-column-dialog {
//     top: 0 !important;
//     left: 0 !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 0 !important;
//   }
// }

// @media only screen and (min-width: 600px) {
//   .v2-add-column-dialog {
//     top: 3% !important;
//     left: 0 !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 0 !important;
//   }
// }

// @media only screen and (min-width: 768px) {
//   .v2-add-column-dialog {
//     top: 4% !important;
//     left: 0 !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 0 !important;
//   }
// }

// @media only screen and (min-width: 812px) {
//   .v2-add-column-dialog {
//     top: 5% !important;
//     left: -5% !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 0 !important;
//   }
// }

@media only screen and (min-width: 1300px) {
  .v2-add-column-dialog {
    --padding: 41.3px;
    width: calc(100% - 50px) !important;
    max-width: 1240px !important;
    left: 6% !important;
    // height: 90vh !important;
    max-height: 924px !important;
  }

  // .ant-drawer-left > .ant-drawer-content-wrapper {
  //   left: 0 !important;
  // }
}

// @media only screen and (min-width: 1026px) {
//   .v2-add-column-dialog {
//     top: 7% !important;
//     left: -15% !important;
//   }

//   .ant-drawer-left > .ant-drawer-content-wrapper {
//     left: 40px !important;
//   }
// }

//antd css start

.v2-drawer.dark-mode .ant-drawer-body td {
  background: transparent !important;
}

.v2-drawer-article.dark-mode .ant-drawer-body td {
  background: transparent !important;
}

.v2-drawer-article-mobile.dark-mode .ant-drawer-body td {
  background: transparent !important;
}

.v2-drawer {
  .share-icon-container {
    text-align-last: end;
  }

  .article-detail-text {
    margin-bottom: 1rem;

    a {
      color: #66dbc4;
    }
    p {
      margin-bottom: 1rem;
    }
  }

  .article-detail-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-height: 140px;
    object-fit: contain;
  }

  .article-detail-container {
    h2,
    h3 {
      font-size: 15px !important;
    }
  }
}

.v2-drawer-article {
  .share-icon-container {
    text-align-last: end;
  }

  .article-detail-text {
    margin-bottom: 1rem;

    a {
      color: #66dbc4;
    }
    p {
      margin-bottom: 1rem;
    }
  }

  .article-detail-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-height: 140px;
    object-fit: contain;
  }

  .article-detail-container {
    h2,
    h3 {
      font-size: 15px !important;
    }
  }
}

.v2-drawer-article-mobile {
  .share-icon-container {
    text-align-last: end;
  }

  .article-detail-text {
    margin-bottom: 1rem;
    a {
      color: #66dbc4;
    }
    p {
      margin-bottom: 1rem;
    }
  }

  .article-detail-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-height: 140px;
    object-fit: contain;
  }

  .article-detail-container {
    h2,
    h3 {
      font-size: 15px !important;
    }
  }

  .breaking-tag {
    padding: 0px 4px 1px 4px !important;
    text-transform: uppercase;
  }

  .ant-drawer-body::-webkit-scrollbar {
    display: none;
  }
}

// .ant-tabs-nav {
//   margin: 0 0 -15px 0px !important;
// }

//antd css end

//CSS By Paul G
.react-grid-item.react-draggable.cssTransform.react-resizable {
  background: transparent !important;
}

//Css by Paul G ended

.v2-column-header.ant-row {
  border-bottom: 2px solid #8989a1;

  row-gap: 0px;
}

.video-player.v2-column-header.ant-row.element.style {
  row-gap: 0px;

  border-bottom: 2px solid #8989a1;
}

iframe {
  overflow-clip-margin: content-box !important;

  border-width: 0px !important;

  border-style: inset !important;

  border-color: transparent !important;

  border-image: none !important;

  overflow: clip !important;
}

.cky-banner-element {
  padding: 8px 30px;
  background: #f8f9fa;
  color: #858a8f;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}

.popup-ads-container-only-img {
  position: fixed;
  left: 55px;
  width: 480px;
  height: max-content;
  min-height: 180px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 3px 3px 3px 0px rgba(221, 221, 221, 0.2);
  z-index: 900;
  // padding: 12px;

  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }
}

.popup-ads-container {
  position: fixed;
  left: 55px;
  // width: 480px;
  width: fit-content;
  height: max-content;
  // min-height: 180px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  // box-shadow: 3px 3px 3px 0px rgba(221, 221, 221, 0.2);
  z-index: 900;
  // padding: 12px;
  padding: 2px;

  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .info-container {
    width: 340px;

    h4 {
      color: #000;
      font-family: "Noto Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
    }
    p {
      color: #000;
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }

  .standard-btn-ads {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #5e5e5e;
    background: #fff;
    font-size: 15px;
    line-height: 18px;
    width: 110px;
    height: 28px;
    margin-top: 12px;
  }

  .standard-btn-ads:hover {
    background-color: #f4f4f6 !important;
  }

  .important-news {
    color: #aeaeae;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
    margin-left: 2px;
    width: 310px;
    font-size: 11px;
    line-height: 16px;
  }

  .ads-icon {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 87.3%;
    transform: translate(-50%, -50%);
  }

  .ad-logo-botton {
    color: #454e5c;
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 500;
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 11px;
    line-height: 20px;
  }
}

.ant-radio-grp {
  height: 350px;
  overflow-y: auto;
}

.ant-radio-grp::-webkit-scrollbar {
  width: 8px !important; /* Set the width of the scrollbar */
}

.ant-radio-grp::-webkit-scrollbar-thumb {
  background-color: #d4d6dd !important; /* Scrollbar thumb color */
}

.ant-radio-grp::-webkit-scrollbar-track {
  background-color: #f1f1f4 !important;
}

@media only screen and (max-height: 720px) {
  .ant-modal-content {
    // background-color: transparent !important;
    // left: -3% I !important;
  }
  .ant-modal {
    left: 2% !important;
  }

  .ant-radio-grp {
    height: 268px !important;
    overflow-y: auto;
  }
  .v2-add-column-dialog
    .ant-modal-body
    .app-selector-list-container
    .ant-tabs-content-holder
    .ant-tabs-content
    > div {
    max-height: 311px;
  }
  .v2-drawer .app-selector-list-container .ant-tabs-card {
    height: 374px;
  }
  .v2-add-column-dialog {
    .ant-modal-body {
      // left: -2% !important;
      height: 640px !important;
    }
  }
  // .v2-add-column-dialog {
  //   --padding: 30px !important;
  // }
  .app-selector-button-group button {
    top: -14px !important; /* Increase the top property to -14px */
  }
}
