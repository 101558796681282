//COMMENT: Css for dark mode starts
.v2-authenticated-page.dark-mode {
  .standard-btn {
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    background: #303742;
    color: #d5d5d5;
    -webkit-text-stroke-width: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }
  .standard-btn.disabled,
  .standard-btn.disabled:hover {
    background-color: initial !important; /* Reset background */
    color: inherit !important; /* Reset color */
    cursor: not-allowed !important; /* Optional: Show as disabled */
  }

  .react-grid-item > .react-resizable-handle-s {
    background: url("../../images/ns-icon.svg") rgba(76, 85, 99, 090) 50% / auto
      90% no-repeat;
  }

  .standard-btn:hover {
    background-color: #4c5563 !important;
    color: #d5d5d5 !important;
  }

  .side-bar {
    // background: #384251 !important;
    // background-image: url("../../images/dark-left-nav.svg") !important;
    background-repeat: repeat-y !important;

    background-color: #384251 !important;

    .sidebar-grid-icon,
    .sidebar-article-icon,
    .sidebar-icon {
      filter: brightness(0) invert(1);
      margin: 0;
    }
  }

  .tabSearch
    .search-container
    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
  .tabSearch .search-container .ant-input-affix-wrapper:hover {
    border-color: #fdfdfd;
  }

  .tabSearch .search-container .ant-input-affix-wrapper {
    background: transparent;
    border: 1px solid #9ba0b0;
    height: 24px !important;
    border-radius: 2px;
  }

  // input,
  // .ant-select-selector {
  //   background: #ced0d4 !important;
  // }

  // input::placeholder {
  //   color: #3c3b3b;
  // }

  .btn {
    background: #515a67 !important;
    color: #d5d5d5;
  }

  .card-highlight-due-to-keyword {
    background-color: #515a67 !important;
    border: 2px solid #000 !important;
  }

  //Scroll Bar Style Start
  ::-webkit-scrollbar {
    width: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    box-shadow: none !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }

  .main-panel {
    background-color: #303742 !important;
  }

  //Save Dialog Style Start
  .edit-save-dialog {
    .save-dialog-btn {
      border: 1px solid #ffffff;
      background-color: transparent;
      color: white;
    }

    .cancel-dialog-btn {
      border: 1px solid #ffffff;
      background-color: transparent;
      color: white;
    }
  }

  //Save Dialog Style End

  .v2-edit-panel {
    .ok-dialog-btn {
      border: 1px solid #ffffff;
      color: #ffffff;
    }
  }

  .ant-space-item {
    color: #9ca1a8 !important;
  }

  .suite-grid-layout {
    // background-size: 100% 100%;
    // height: 100% !important;
    min-height: 1100px;
    // background: url("../../images/bg-diagonal-lines-dark-theme.svg");
    background: url("../../images/grid-lines-dark-extended.svg");

    // background-repeat: repeat;
    // background: none !important;
  }

  .v2-suite-grid-container {
    .ant-radio-wrapper {
      line-height: 2 !important;

      // color: #9ca1a8 !important;
      div {
        color: #9ca1a8 !important;
      }
    }

    .widget-container {
      border-color: #9ca1a8;
      .tradingview-widget-copyright {
        border-bottom: 1px solid #9ca1a8;
        border-left: 1px solid #9ca1a8;
        border-right: 1px solid #9ca1a8;
        background: #303742;
        font-size: 11px !important;

        .blue-text {
          color: #ffffff !important;
        }
      }
    }

    .v2-suite-column {
      background: #384251 !important;
      margin: 0 0 0 4px !important;
    }

    .lodemore-button-container {
      background-color: #384251;
      box-shadow: 1px 1px 10px #bbbbc9 !important;

      .button {
        background-color: #384251;
        color: #fff;
      }

      .button:hover {
        background-color: #66dbc4 !important;
        color: white;
        max-width: 100%;
        transform: translateY(-2px) !important;
      }
    }

    .v2-column-header {
      background: #515a67 !important;
      border-top: 1px solid #9ca1a8 !important;
      border-left: 1px solid #9ca1a8 !important;
      border-right: 1px solid #9ca1a8 !important;
      border-bottom: 2px solid #9ca1a8 !important;
      margin: 4px 0 0 4px !important;

      .antrow {
        color: white !important;
        border-color: #9ca1a8 !important;
      }

      .v2-column-header-text {
        color: #ced0d4 !important;
      }
    }

    .v2-video-column-header {
      background: #515a67 !important;
      border-top: 1px solid #9ca1a8 !important;
      border-left: 1px solid #9ca1a8 !important;
      border-right: 1px solid #9ca1a8 !important;
      border-bottom: 2px solid #9ca1a8 !important;
      margin: 0 !important;

      .antrow {
        color: white !important;
        border-color: #9ca1a8 !important;
      }

      .v2-column-header-text {
        color: #ced0d4 !important;
      }
    }

    .v2-column-header-option {
      border: 1px solid #9ca1a8;

      .feed-menu-x-lists-icon {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%);
      }

      .settings-divider {
        background-color: #9ca1a8 !important;
      }

      .ant-radio-wrapper {
        line-height: 2 !important;

        color: #9ca1a8 !important;
      }

      .v2-edit-panel-collapse1 {
        background-color: #384251 !important;
        color: #9ca1a8 !important;
      }

      svg {
        fill: #9ca1a8 !important;
      }

      div:not(.ant-select-selector) {
        color: #9ca1a8 !important;
      }

      background-color: #384251 !important;
      color: #9ca1a8 !important;

      .v2-edit-panel {
        color: #9ca1a8 !important;

        .v2-edit-panel-collapse {
          background-color: #384251 !important;
          color: #9ca1a8 !important;
        }

        .save-dialog {
          background-color: #384251 !important;

          box-shadow: 1px 1px 10px #bbbbc9 !important;

          .cancel-dialog-btn {
            background: transparent;
            box-shadow: 2px 2px 4px 0px #bbbbc9 !important;
          }

          .save-dialog-btn {
            box-shadow: 2px 2px 4px 0px #bbbbc9 !important;

            background: transparent;
          }
        }
      }

      .v2-column-header-option-close-btn {
        background-color: #384251;

        .v2-column-header-option-close-btn-icon {
          color: #bbbbc9;
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
            brightness(100%) contrast(100%);
        }
      }

      .ant-collapse-item {
        .ant-space-item {
          color: #9ca1a8 !important;
        }
      }
    }

    .v2-column-header-option::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    .v2-column-header-option::-webkit-scrollbar-thumb {
      background: rgb(255, 255, 255);
      border-radius: 10px;
    }

    /* Styling for Firefox */
    .v2-column-header-option {
      scrollbar-color: rgb(255, 255, 255) transparent;
      /* thumb and track color */
    }

    .v2-video-contatiner {
      border: 1px solid #9ca1a8;
    }

    .view-chart-div {
      // .view-chart-div__loading {
      //   color: #fff;
      //   border: 1px solid #9ca1a8;
      //   background:#384251 ;
      // }
      iframe {
        border: 1px solid #9ca1a8 !important;
        // border: 1px solid #d5d5d5 !important;
      }

      .view-chart {
        background: #384251 !important;
        border: 1px solid #9ca1a8;
      }

      .calender-chart {
        background: #384251 !important;
        border: 1px solid #9ca1a8;
      }
    }

    .v2-suite-column-card-item-reuters {
      background: #384251;
      border: 1px solid #9ca1a8;
      border-radius: 4px !important;

      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%);
      }

      // color: white;

      // .share-icon-div {
      //   img {
      //     filter: invert(83%) sepia(48%) saturate(425%) hue-rotate(104deg)
      //       brightness(90%) contrast(89%);
      //   }
      // }

      .v2-suite-column-main-title {
        color: #ffffff;
      }

      .time-label {
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #66dbc4;
      }
    }

    .investing-credit {
      color: #8989a1;
      background: #384251;
      border: 1px solid #9ca1a8;

      a {
        color: #3496f8;
      }
    }

    .ant-alert-warning {
      background-color: #384251 !important;
      border: #384251 !important;

      div {
        color: white !important;
      }
    }

    .v2-suite-column-card-item {
      background-color: #384251;
      border: 1px solid #9ca1a8;
      border-radius: 4px !important;
      box-shadow: 0px 0px 0px 0px rgba(221, 221, 221, 0.2) !important;
      color: #fff !important;

      .share-icon-div {
        img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
            brightness(100%) contrast(100%);
        }
      }

      .time-label {
        color: #66dbc4 !important;
      }

      .rumble-content {
        // color: #fff !important;
        color: #ffffff !important;
      }

      .twitter-user {
        color: #afb3b9 !important; //Dark Mode
      }

      .retweeted-user {
        color: #fff !important;
      }

      .v2-suite-column-title {
        color: #fff !important;
      }

      .v2-suite-column-main-title {
        color: #ffffff !important;
      }

      .v2-suite-column-card-title {
        // color: #fff !important;
        color: #ced0d4 !important;
      }

      .news-feeds-description {
        color: #d5d5d5 !important;

        p {
          color: #fff !important;

          a {
            color: #66dbc4 !important;
          }
        }
      }
    }

    // .add-icon-div {
    //   background-color: #384251;
    //   box-shadow: 1px 1px 10px #bbbbc9 !important ;
    //   img {
    //     filter: brightness(0) invert(1);
    //   }
    // }
  }
}

//Drawer css start

.v2-drawer.dark-mode {
  // margin-left: 37px;
  .ant-drawer-close {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }

  .please-verify-text {
    color: #fff;
  }

  .ant-drawer-content {
    background-color: #515a67 !important;
  }

  .article-list-container {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }

    .results {
      color: #ced0d4;
    }

    .article-list-title {
      color: white !important;
      .highlighted_search_text {
        background: rgba(0, 221, 192, 0.2);
      }
    }

    .time-div {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;
    }

    .article-list-item {
      background: #454e5c !important;
      color: #fff !important;
    }
  }

  .article-detail-container {
    background-color: #454e5c !important;

    .breadcrumb {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;

      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%);
      }
    }
  }

  .article-detail-text {
    h2 {
      color: white !important;
    }

    p {
      color: white !important;
    }
  }

  .ant-tabs-tab {
    background-color: #6a727d !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-tab:hover {
    background-color: #d5d5d5 !important;

    .ant-tabs-tab-btn {
      color: #1d1d4b !important;
    }
  }

  .ant-tabs-tab-active {
    background-color: #303742 !important;
    border-radius: 50px !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-ink-bar {
    background: white !important;
  }

  svg {
    fill: #9ca1a8 !important;
  }

  .delete-dialog-btn {
    svg {
      fill: white !important;
    }
  }

  .reuters-list-first-verion {
    background: rgba(235, 80, 58, 0.8) !important;
    color: #fff !important;
  }

  .divider {
    background: white !important;
  }

  .share-icon {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-wrapper-body {
    background-color: #303742 !important;
  }

  .cancel-dialog-btn {
    background-color: #fff;
    color: #384251;
  }

  input {
    background-color: #303742 !important;
    color: #fff !important;
  }

  input::placeholder {
    color: #fff;
  }

  .enter-password-dialog {
    background-color: #384251;
    color: #fff;
  }

  .continue-dialog {
    background-color: #384251 !important;
    color: #fff;

    .submit-dialog-btn {
      background-color: #fff;
      color: #384251;
    }
  }

  .refresh-icon.link {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-header {
    background-color: #303742;
    font-weight: 600;

    .ant-drawer-title {
      color: #9ca1a8 !important;
    }
  }

  .ant-drawer-body {
    .standard-btn {
      border-radius: 5px;
      border: 1px solid #d5d5d5 !important;
      background: #303742;
      color: #d5d5d5;
      -webkit-text-stroke-width: 1;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }
    .standard-btn.disabled,
    .standard-btn.disabled:hover {
      background-color: initial !important; /* Reset background */
      color: inherit !important; /* Reset color */
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }

    .standard-btn:hover {
      background-color: #4c5563 !important;
      color: #d5d5d5 !important;
    }

    svg {
      fill: #fff;
    }

    background-color: #303742;

    div {
      color: #fff;
    }

    h4 {
      color: #fff;
    }

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }

    td {
      color: #fff;
    }

    tr {
      color: #fff;
    }
  }

  .v2-subscription-container {
    .body-table {
      td {
        background: #4c5563 !important;
        border: 1px solid #9ca1a8;
        color: #fff !important;
      }
    }

    .footer-table {
      .first-item,
      .second-item,
      .third-item {
        background-color: #303742;
      }

      td {
        border: 1px solid #9ca1a8;
      }
    }
  }
}

.v2-drawer-article.dark-mode {
  // margin-left: 37px;
  .ant-drawer-close {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }

  .ant-drawer-content {
    background-color: #515a67 !important;
  }

  .article-list-container {
    .results {
      color: #ced0d4;
    }

    .unpaid-app-container {
      border: 1px dashed #fff;
      background: #4c5563;
      .unpaid-app-msg {
        color: #fff;
        span {
          color: #fff;
        }
      }

      .unpaid-botton-contain {
        display: flex;
        margin-top: 20px;

        .unpaid-buy-now-btn {
          cursor: pointer;
          width: 141px;
          height: 24px;
          flex-shrink: 0;
          border-radius: 4px;
          border: 1px solid #ff644e;
          background: #ff644e;
          color: #fff;
          font-family: Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 100% */
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .unpaid-para {
          color: #9ca1a8;

          span {
            font-weight: 700 !important;
          }
        }
      }
    }
    // img {
    //   filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
    //     brightness(100%) contrast(100%);
    // }

    .article-list-title {
      color: white !important;
      .highlighted_search_text {
        background: rgba(0, 221, 192, 0.2);
      }
    }

    .time-div {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;
    }

    .article-list-item {
      background: #454e5c !important;
      color: #fff !important;
    }
  }

  .article-detail-container {
    background-color: #454e5c !important;

    .breadcrumb {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;

      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%);
      }
    }
  }

  .article-detail-text {
    h2,
    p,
    span {
      color: white !important;
    }

    div,
    span,
    p {
      background-color: transparent !important;
      background: transparent !important;
    }
  }

  .ant-tabs-tab {
    background-color: #6a727d !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-tab:hover {
    background-color: #d5d5d5 !important;

    .ant-tabs-tab-btn {
      color: #1d1d4b !important;
    }
  }

  .ant-tabs-tab-active {
    background-color: #303742 !important;
    border-radius: 50px !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-ink-bar {
    background: white !important;
  }

  svg {
    fill: black !important;
  }

  .reuters-list-first-verion {
    background: rgba(235, 80, 58, 0.8) !important;
    color: #fff !important;
  }

  .divider {
    background: white !important;
  }

  .share-icon {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-wrapper-body {
    background-color: #303742 !important;
  }

  .cancel-dialog-btn {
    background-color: #fff;
    color: #384251;
  }

  input {
    background-color: #303742 !important;
    color: #fff;
  }

  input::placeholder {
    color: #fff;
  }

  .enter-password-dialog {
    background-color: #384251;
    color: #fff;
  }

  .continue-dialog {
    background-color: #384251 !important;
    color: #fff;

    .submit-dialog-btn {
      background-color: #fff;
      color: #384251;
    }
  }

  .refresh-icon.link {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-header {
    background-color: #303742;
    font-weight: 600;

    .ant-drawer-title {
      color: #9ca1a8 !important;
    }
  }

  .ant-drawer-body {
    .standard-btn {
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      background: #303742;
      color: #d5d5d5;
      -webkit-text-stroke-width: 1;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }

    .standard-btn:hover {
      background-color: #4c5563 !important;
      color: #d5d5d5 !important;
    }
    .standard-btn.disabled,
    .standard-btn.disabled:hover {
      background-color: initial !important; /* Reset background */
      color: inherit !important; /* Reset color */
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }

    svg {
      fill: #fff;
    }

    background-color: #303742;

    div {
      color: #fff;
    }

    h4 {
      color: #fff;
    }

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }

    td {
      color: #fff;
    }

    tr {
      color: #fff;
    }
  }

  .v2-subscription-container {
    .body-table {
      td {
        background: #384251 !important;
        color: #fff !important;
        border: 1px solid #9ca1a8;
      }
    }

    .footer-table {
      td {
        border: 1px solid #9ca1a8;
      }
    }
  }
}

.v2-drawer-article-mobile.dark-mode {
  // margin-left: 37px;
  .ant-drawer-close {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }
  }

  .ant-drawer-content {
    background-color: #515a67 !important;
  }

  .article-list-container {
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%);
    }

    .atrticle-tabs-list-for-mobile {
      .ant-tabs-nav-wrap {
        background-color: #303742;
      }
    }

    .article-list-title {
      color: white !important;
      .highlighted_search_text {
        background: rgba(0, 221, 192, 0.2);
      }
    }

    .time-div {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;
    }

    .article-list-item {
      background: #454e5c !important;
      color: #fff !important;
    }
  }

  .article-detail-container {
    background-color: #454e5c !important;

    .breadcrumb {
      color: white !important;
    }

    .share-icon-container {
      margin-right: 5px;
      text-align-last: end;

      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%);
      }
    }
  }

  // .article-detail-text {
  //   h2 {
  //     color: white !important;
  //   }

  //   p {
  //     color: white !important;
  //   }
  // }

  .ant-tabs-tab {
    background-color: #6a727d !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-tab:hover {
    background-color: #d5d5d5 !important;

    .ant-tabs-tab-btn {
      color: #1d1d4b !important;
    }
  }

  .ant-tabs-tab-active {
    background-color: #303742 !important;
    border-radius: 50px !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }

  .ant-tabs-ink-bar {
    background: white !important;
  }

  svg {
    fill: black !important;
  }

  .reuters-list-first-verion {
    background: rgba(235, 80, 58, 0.8) !important;
    color: #fff !important;
  }

  .divider {
    background: white !important;
  }

  .share-icon {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-wrapper-body {
    background-color: #303742 !important;
  }

  .cancel-dialog-btn {
    background-color: #fff;
    color: #384251;
  }

  input {
    background-color: #303742 !important;
    color: #fff;
  }

  input::placeholder {
    color: #fff;
  }

  .enter-password-dialog {
    background-color: #384251;
    color: #fff;
  }

  .continue-dialog {
    background-color: #384251 !important;
    color: #fff;

    .submit-dialog-btn {
      background-color: #fff;
      color: #384251;
    }
  }

  .refresh-icon.link {
    filter: brightness(0) invert(1);
  }

  .ant-drawer-header {
    background-color: #303742;
    font-weight: 600;

    .ant-drawer-title {
      color: #ffffff !important;
    }
  }

  .ant-drawer-body {
    .standard-btn {
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      background: #303742;
      color: #d5d5d5;
      -webkit-text-stroke-width: 1;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
    }

    .standard-btn:hover {
      background-color: #4c5563 !important;
      color: #d5d5d5 !important;
    }
    .standard-btn.disabled,
    .standard-btn.disabled:hover {
      background-color: initial !important; /* Reset background */
      color: inherit !important; /* Reset color */
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }

    svg {
      fill: #fff;
    }

    background-color: #303742;

    div {
      color: #fff;
    }

    h4 {
      color: #fff;
    }

    h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }

    td {
      color: #fff;
    }

    tr {
      color: #fff;
    }
  }

  .v2-subscription-container {
    .body-table {
      td {
        background: #384251 !important;
        color: #fff !important;
      }
    }

    .footer-table {
      td {
        border: 1px solid transparent;
      }
    }
  }
}

//Drawer css end

//app selector css start
.v2-add-column-dialog.dark-mode {
  // .ant-tabs-nav {
  //   border-bottom: 1px solid #9ca1a8 !important;
  //   border-top: 1px solid #9ca1a8 !important;
  // }

  .cancel-btn {
    // background: none !important;
    // color: #fff;
    border: 1px solid #a3aac2 !important;
    background: none !important;
    color: #fdfdfd !important;
  }

  .cancel-btn:hover {
    background: #5e616e !important;
    border: 1px solid #a3aac2 !important;
  }

  .app-selector-list-container {
    .ant-tabs-content-holder {
      color: #fff;
    }

    .ant-radio-button-wrapper-checked,
    .ant-radio-button-wrapper {
      &:not([class*=" ant-radio-button-wrapper-disabled"]) {
        .app-select-label {
          background: unset !important;
          color: #fdfdfd;
        }
      }
    }

    .ant-radio-button-wrapper-checked {
      &:not([class*=" ant-radio-button-wrapper-disabled"]) {
        background: #323643;
        color: black;

        &:hover {
          background: #464a57 !important;
        }
      }
    }
  }

  .app-select-icon-container .app-select-icon {
    height: 100%;
    width: 100%;
    border-radius: 1.78px;
    border: 0.36px solid #a9adbc !important;
    display: block;
    object-fit: contain;
  }

  .app-selector-button-group {
    background-color: #384251 !important;

    .standard-btn {
      border-radius: 4px;
      // border: 1px solid #d5d5d5;
      border: none;
      background: #525a7a;
      color: #fdfdfd;
      -webkit-text-stroke-width: 1;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
    }

    .standard-btn:hover {
      background-color: #667299 !important;
      color: #fdfdfd !important;
    }
    .standard-btn.disabled,
    .standard-btn.disabled:hover {
      background-color: #525a7a !important; /* Reset background */
      color: #fdfdfd !important; /* Reset color */
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }
    .cancel-btn.disabled,
    .cancel-btn.disabled:hover {
      background-color: transparent !important;
      color: #fdfdfd !important; /* Reset color */
      cursor: not-allowed !important; /* Optional: Show as disabled */
    }
  }

  .ant-radio-wrapper .tile-select-name {
    color: #a9adbc !important;
  }

  .ant-radio-wrapper-checked .tile-select-name {
    color: #fdfdfd !important;
  }
  .ant-radio-wrapper-checked .app-selector-drawer-main-img {
    filter: brightness(2) !important;
  }

  // .ant-radio-wrapper
  .app-select-container {
    background: #384251;
    color: white;
    border: 1px solid #9ba0b0 !important;

    &:hover {
      color: #fff;
      background: #5e616e;

      .app-select-label {
        color: inherit;
      }
    }

    &.ant-radio-button-wrapper-disabled {
      &:not(.active) {
        background-color: transparent !important;
        opacity: 0.5;
        border: 1px solid #9ba0b0 !important;

        color: #fdfdfd !important;
        .app-select-label:hover {
          // color: #515a67 !important;
        }
      }
      // &:hover,
      // .app-select-label:hover {
      //   color: #fff !important;
      // }

      &.active {
        background: #323643 !important;
        // opacity: 0.7;
        color: #fdfdfd !important;
        border: 1px solid #9ba0b0 !important;

        &:hover,
        .app-select-label:hover {
          color: #fff !important;
          // background: #515a67;
        }
      }
    }
  }

  .ant-radio-grp::-webkit-scrollbar {
    width: 8px !important;
  }

  .ant-radio-grp::-webkit-scrollbar-thumb {
    background-color: #515a67 !important;
  }

  .ant-radio-grp::-webkit-scrollbar-track {
    background-color: #303742 !important;
  }

  .standard-btn {
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    background: #303742;
    color: #d5d5d5;
    -webkit-text-stroke-width: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }

  .standard-btn:hover {
    background-color: #4c5563 !important;
    color: #d5d5d5 !important;
  }

  .ant-modal-content {
    background-color: #384251 !important;
    // border: 2px solid #9ca1a8;
    color: white;
    // border: 2px solid #384251 !important;
    align-items: center !important;

    .ant-modal-close-x {
      img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
          brightness(100%) contrast(100%) !important;
      }
    }

    .ant-modal-header {
      background-color: #384251 !important;

      .ant-modal-title {
        color: #fff;
        font-weight: 600 !important;
        letter-spacing: 0.4px !important;
        line-height: 30px;
        /* 100% */
        // letter-spacing: 0.9px;
      }
    }
  }

  .ant-modal-body {
    background-color: #384251;

    .main-topic-app-selector-tabs {
      .ant-tabs-top {
        border-color: #9ca1a873;
        width: 1180px;
        height: 36px;
      }
    }

    .ant-radio-button-wrapper-disabled.restrict {
      .app-select-label {
        background-color: transparent !important;
      }
    }

    .ant-radio-button-wrapper-checked {
      .app-select-label {
        background-color: #e9e9ed;
        color: #384251;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #323643 !important;
      border-color: #3f9e60 !important;
      border-style: dashed !important;
      border-width: 1px !important; /* Adjust thickness */
    }

    .ant-tabs-tab {
      border: 1px solid #9ca1a873;
      background: #384251 !important;

      &:first-child {
        border-top: none;
        border-left: none;
      }

      & + .ant-tabs-tab {
        border-left: none;
        border-top: none;
      }

      & + .ant-tabs-tab:hover {
        background: #5e616e !important;
        color: #c6c9d2 !important;
        .ant-tabs-tab-btn {
          color: #c6c9d2 !important;
        }
      }

      .ant-tabs-tab-btn {
        color: #c6c9d2 !important;
      }

      .ant-tabs-tab-btn[aria-selected="true"] {
        color: #fdfdfd !important;
      }
    }

    .ant-tabs-card {
      border-color: #9ca1a873;
    }

    .app-selector-drawer-box {
      .app-selector-drawer {
        height: 85px !important;
        border-color: #9ca1a873;
      }
    }

    .app-selector-button-group {
      border-color: #9ca1a873;
    }

    .ant-tabs-tab-active {
      background: #525a7a !important;
      border-bottom-color: #9ca1a873 !important;
    }
  }

  .ant-tabs-tab-active:hover {
    background: #5e616e !important;

    .ant-tabs-tab-btn[aria-selected="true"] {
      color: #c6c9d2 !important;
    }
    // border-bottom-color: #9ca1a873 !important;
  }

  .ant-tabs-tab:hover {
    background: #5e616e !important;

    .ant-tabs-tab-btn[aria-selected="true"] {
      color: #c6c9d2 !important;
    }
    // border-bottom-color: #9ca1a873 !important;
  }

  .ant-tabs-top {
    position: relative !important;
  }

  span.ant-radio + * {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ant-radio-wrapper {
    font-weight: 500;
    color: #c6c9d2 !important;
  }

  .ant-radio-wrapper:hover {
    font-weight: 500;
    color: #fdfdfd !important;
    .tile-select-name {
      color: #fdfdfd !important;
      transition: 0.3s ease-in !important;
    }
    .app-selector-drawer-main-img {
      filter: brightness(2) !important;
      transition: 0.3s ease-in !important;
    }
  }

  // .ant-radio-wrapper:hover {
  //   color: #fdfdfd !important;
  // }

  .tv-top-section .ant-radio-wrapper-checked {
    color: #fdfdfd !important;
    border-bottom: 1px solid #fff !important;
  }

  .tab-category-container {
    &.partner-feed {
      .filter-icon {
        color: #ced0d4;
        background: #303742;

        &:hover {
          background: #384251;
        }

        &.active {
          background: #515a67;
        }
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: none !important;
        border: none;
        &:hover {
          background: none !important;
          .ant-tabs-tab-btn {
            color: #fdfdfd !important;
          }
        }

        .ant-tabs-tab-btn {
          // color: #ced0d4 !important;
        }
      }
      .ant-tabs-tab-active {
        background: none !important;
        border-bottom: 1px solid #fff !important;

        .ant-tabs-tab-btn {
          color: #fdfdfd !important;
        }
      }
    }

    .selected-filters-container {
      .selected-filter-item {
        background: #32364380;
        color: #e2e4e9;
        border: 1px solid #9ba0b0;

        svg {
          cursor: pointer;
        }

        &:hover {
          // background:
        }
      }
    }
  }
}

//app selector css end

//logout modal css start
// .v2-logout-modal.dark-mode {
//   .logout-modal-title {
//     color: white;
//   }

//   button {
//     border: 1px solid white;
//     color: white;
//   }
// }
//logout modal css end
.single_premium {
  .premium-select-title {
    // color: #fff !important;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%) !important;
    }
  }

  .checked_icon {
    // border: 1px solid #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%) !important;
    }
  }
}

.premium_terms {
  div {
    p {
      // color: white !important;
    }
  }

  .checked_terms {
    // border: 1px solid #fff;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
        brightness(100%) contrast(100%) !important;
    }
  }
}

.ant-tabs-ink-bar {
  background-color: none !important;
  visibility: hidden;
}

.list-container-article-viewer {
  .unpaid-app-container {
    border: 1px dashed #fff;
    background: #4c5563;
    .unpaid-app-msg {
      color: #fff;
      span {
        color: #fff;
      }
    }

    .unpaid-botton-contain {
      display: flex;
      .unpaid-buy-now-btn {
        cursor: pointer;
        border: 1px solid #ff644e;
        background: #ff644e;
        color: #fff;
      }

      .unpaid-para {
        color: #9ca1a8;

        span {
          font-weight: 700 !important;
        }
      }
    }
  }
}

.dark_header_nav {
  background: #384251;

  .search_box {
    .input {
      color: #ced0d4;
      border: 1px solid #9ca1a8;
      background: #303742;
      caret-color: #00ddc0;
    }

    input[type="search"]::-webkit-search-cancel-button {
      background-image: url("../../images/clear_icon_search_dark.svg");
    }

    .input::placeholder {
      color: #ced0d4;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_searchbox_article {
  .search_box {
    .group {
      display: flex;
      line-height: 28px;
      align-items: center;
      position: relative;
      width: 98%;
      margin-bottom: 23px;
      margin-top: 15px;
    }

    .disabled {
      cursor: pointer !important;
    }

    .input {
      height: 42px;
      line-height: 28px;
      padding: 0 7.22px;
      padding-left: 39px;
      border-radius: 4px;
      border: 1px solid #9ca1a8;
      background: #303742;
      outline: none;
      color: #fff;
      width: 100%;
      caret-color: #00ddc0;
      font-size: 16px;
    }
    .input:focus {
      border: 1px solid #00ddc0;
    }

    .clear_btn {
      width: 94px;
      height: 42px;
      flex-shrink: 0;
      border-radius: 0px 4px 4px 0px;
      border-top: 1px solid #00ddc0;
      border-right: 1px solid #00ddc0;
      border-bottom: 1px solid #00ddc0;
      background: #454e5c;
      position: absolute;
      z-index: 999;
      right: 0;
      color: #fff !important;
      text-align: center;
      font-family: "Helvetica" !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px; /* 71.429% */
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in;
      cursor: pointer;
    }

    .not_focused {
      display: none;
    }

    .input::placeholder {
      color: #9b9b9b;
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 118.75% */
    }

    .icon {
      position: absolute;
      left: 14px;
      width: 16.5px;
      height: 16.5px;
      filter: none !important;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_search_list_container::-webkit-scrollbar {
  display: none;
}

.dark_search_list_container,
.dark_bookmark_list_container {
  background: #515a67;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.12);

  .results {
    color: #ced0d4;

    background: #515a67;
  }
  .closeIcon {
    filter: invert(1);
  }

  .topics {
    color: #ced0d4;
  }

  .card_container {
    &:not(:last-child) {
      border-bottom: 1px solid #e8e8e8;
    }

    .card_top_container {
      display: flex;
      width: 100%;
      .left {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        img {
          filter: invert(1);
        }
      }
      .title_img {
        display: flex;
        align-items: center;
        margin-top: 5px;
        img {
          width: 28px;
          height: 28px;
          border-radius: 4px;
        }
      }
      .right {
        margin-left: 7px;
        width: 100%;
        .source_topic {
          color: #ced0d4;
          font-family: "Helvetica Neue", sans-serif !important;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 15px; /* 136.364% */
          text-transform: capitalize;
        }

        .date_time {
          color: #ced0d4;
          font-family: "Helvetica Neue", sans-serif !important;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 10px; /* 100% */
        }
      }
    }

    .main_title {
      color: #fff;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 138.462% */
      cursor: pointer;

      p {
        margin-bottom: 0 !important;
      }
    }

    .second_title {
      color: #fff;
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      cursor: pointer;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_searchbox_feed {
  .search_box {
    .group {
      display: flex;
      line-height: 28px;
      align-items: center;
      position: relative;
      width: 100%;
    }

    .input {
      border-right: 1px solid #9ca1a8;
      border-bottom: 1px solid #9ca1a8;
      border-left: 1px solid #9ca1a8;
      border-top: none;
      background: #303742;
      outline: none;
      color: #ced0d4;
      width: 100%;
      // position: absolute;
      // top: 0;
      // z-index: 99;
      caret-color: #00ddc0;
      border-radius: 0px 0px 2px 2px;
      font-size: 11px;
    }

    .input:focus {
      border: 1px solid #00ddc0;
    }

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      background-image: url("../../images/clear_icon_search_dark.svg");
      background-position: center;
      background-size: cover;
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    .input::placeholder {
      color: #ced0d4;
      font-family: "Helvetica";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 172.727% */
    }

    .icon {
      position: absolute;
      left: 14px;
      width: 12px;
      height: 12px;
      z-index: 1000;
      top: 6px;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_without_search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;

  .para_first {
    color: #ced0d4;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
  }

  .para_second {
    color: #fff;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
    width: 362px;
  }

  .image {
    img {
      filter: none !important;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_loading_card_container {
  width: 103%;
  padding: 8px;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: 7px;
  flex-wrap: wrap;
  height: 71px;
  margin-top: 22px;

  .loading_card_top_container {
    display: flex;
    .dummy_img {
      width: 37.729px;
      height: 28px;
      flex-shrink: 0;
      border-radius: 2px;
      // background: linear-gradient(
      //   91deg,
      //   rgba(56, 66, 81, 0.2) 1.19%,
      //   #e1dfdf 96.6%,
      //   rgba(56, 66, 81, 0.2) 124.35%
      // );
      background: #41485a;
    }
    .right {
      margin-left: 7px;
      // 41485A
      .source_topic {
        width: 215.593px;
        height: 14px;
        flex-shrink: 0;
        // background: linear-gradient(
        //   91deg,
        //   #384251 1.19%,
        //   #f9f8f8 63.86%,
        //   #384251 118.23%
        // );
        background: #41485a;
      }

      .date_time {
        width: 246.585px;
        height: 9px;
        flex-shrink: 0;
        // background: linear-gradient(
        //   91deg,
        //   #384251 1.19%,
        //   #f9f8f8 63.86%,
        //   #384251 118.23%
        // );
        background: #41485a;
        margin-top: 3px;
      }
    }
  }

  .left {
    display: flex;
    width: 100%;
    justify-content: end;
    .box {
      width: 16.169px;
      height: 12px;
      flex-shrink: 0;
      border-radius: 2px;
      border-radius: 1px;
      // background: linear-gradient(
      //   91deg,
      //   rgba(56, 66, 81, 0.2) 1.19%,
      //   #f9f8f8 82.58%,
      //   rgba(56, 66, 81, 0) 118.23%
      // );
      background: #41485a;
      margin-left: 4.04px;
    }
  }

  .loading_main_title {
    width: 477px;
    height: 34px;
    flex-shrink: 0;
    // background: linear-gradient(
    //   91deg,
    //   #384251 1.19%,
    //   #f9f8f8 63.86%,
    //   #384251 118.23%
    // );
    background: #41485a;
    margin-top: 9px;
  }

  .second_title {
    color: #000;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    cursor: pointer;
  }
}

.dark_feed_without_search_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  .para_first {
    color: #ced0d4;
    margin-top: 10px;
    text-align: center;
    font-family: "Helvetica";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .para_second {
    color: #fff;
    margin-top: 6px;
    text-align: center;
    font-family: "Helvetica";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .image {
    img {
      filter: none !important;
    }
  }
  .highlighted_search_text {
    background: rgba(0, 221, 192, 0.2);
  }
}

.dark_social_share_container {
  .social_bookmark_container {
    img {
      filter: none !important;
    }
  }
}

.dark_bookmark_list_container {
  .not_found_container {
    h1,
    p {
      color: #ced0d4;
    }
  }
  .card_container {
    // &:hover {
    //   background: #303742;
    // }
  }
}

.dark-custom-feed-main-container {
  display: flex;
  height: 27px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1px;
  background: #384251;
  width: 160px;
  cursor: pointer;
  margin: 20px;
  border: 0.4px solid #9ca1a8;
  .feed-text {
    color: #ced0d4;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.dark-custom-add-feed-main-container {
  .ant-checkbox-group {
    height: 209px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
  }

  .ant-checkbox-group::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }
  .feed-lists {
    .ant-checkbox + span {
      color: #9ca1a8;
      padding-left: 8px !important;
      font-family: "Helvetica Neue", sans-serif !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px; /* 120% */
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    .ant-checkbox-wrapper {
      margin-top: 2px;
    }

    .ant-checkbox-inner {
      border: 1px solid #d1d1d1;
      width: 12px;
      height: 12px;
      // background-color: #fff;
      border-radius: 3px;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
      border: 1px solid #515a67;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border: 1px solid #515a67;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #515a67;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #515a67;
      border: 1px solid #515a67;
    }

    .ant-checkbox-checked::after {
      border: 1px solid #515a67;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-radius: 1px;
      width: 4px;
      height: 7px;
      margin-top: -0.8px;
    }
  }

  .ant-btn-primary:hover {
    border-color: #515a67;
    background: #515a67;
  }
  .ant-btn-primary:active {
    border-color: #515a67;
    background: #515a67;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #515a67;
    background: #515a67;

    border-radius: 1px;
    margin-top: 10px;
    /* width: 20px; */
    width: 44px;
    height: 19px;
    text-align: center;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding: 0;
  }
  .ant-btn-primary:focus {
    border-color: #515a67;
    background: #515a67;
  }

  .ant-btn[disabled] {
    color: #fff !important;
    border-color: #515a67 !important;
    background: #515a67 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
}

.dark-feed-name-edit-main-container {
  input {
    display: flex;
    width: 96%;
    height: 19px;
    padding: 8px 4px;
    align-items: center;
    gap: 6px;
    border-radius: 1px;
    border: 0.6px #d5d5d5;
    background: #fff;
    color: #3c3b3b;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 190% */
  }
}
