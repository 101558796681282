.tabSearch {
  display: flex;
  gap: 10px;

  .search-container {
    display: flex;
    align-items: center;
    gap: 7.5px;
    width: 160px;
    position: relative;

    svg {
      font-size: 12px;
      color: #9b9b9b;
    }

    .ant-input-affix-wrapper {
      background: transparent;
      border: 1px solid #a9adbc;
      height: 24px !important;
      border-radius: 2px;

      &.ant-input-affix-wrapper-focused {
        border-color: #5e616e;
      }

      &:hover {
        background: transparent !important;
        border-color: #b8bbc7 !important;
        z-index: auto !important;
      }

      &.ant-input-affix-wrapper-focused:hover {
        background: transparent !important;
        border-color: #5e616e !important;
        z-index: auto !important;
      }
    }

    input.ant-input {
      padding: 6px 0px !important;
      font-size: 12px;
      background: transparent;
      caret-color: #5e616e;
    }

    // input {
    //   outline: none;
    //   background: transparent;
    //   border: 1px solid #d5d5d5;
    //   height: 26px;
    //   padding: 7px 10px;
    //   border-radius: 4px;
    //   outline: 1px solid #37aee2;
    //   padding-left: 27px;
    //   caret-color: #37aee2;
    //   &,
    //   &::placeholder {
    //     font-size: 12px;
    //   }

    //   &::placeholder {
    //     padding-left: 2px;
    //   }
    // }
  }

  .filter-container {
    .ant-select {
      //   width: 140px;

      &:hover {
        .ant-select-selector {
          border-color: #d5d5d5 !important;
          background: #f2f2f433;
        }
      }

      &.ant-select-focused {
        .ant-select-selector {
          border-color: #d5d5d5 !important;
          background: #f2f2f433;
          box-shadow: none !important;
        }
      }

      .ant-select-arrow {
        & > div {
          padding-left: 2px;
        }
      }

      .ant-select-selection-item {
        &:before {
          content: "2";
          position: absolute;
        }
      }

      .ant-select-item-option-selected {
        background: transparent !important;
      }

      .ant-select-selector {
        background: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 4px;
        height: 26px;
        padding: 6px;

        .ant-select-selection-overflow {
          display: none;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          color: #3c3b3b;
          position: relative;
          font-size: 12px;
        }
      }
    }
  }
}

.tabSearch.dark .ant-input-affix-wrapper {
  background: transparent;
  border: 1px solid #757a8a !important;
  z-index: auto;

  &:hover {
    background: transparent !important;
    border-color: #9ba0b0 !important;
    z-index: auto !important;
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    background: transparent !important;
    border-color: #fdfdfd !important;
    z-index: auto !important;

    &:hover {
      background: transparent !important;
      border-color: #fdfdfd !important;
      z-index: auto !important;
    }
  }
}

.tabSearch.dark input.ant-input {
  color: #fdfdfd;
  caret-color: #fdfdfd;
}
