// Base CSS Reset (SCSS)

*,
*::before,
*::after,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
ul,
li,
a {
  margin: 0;
  padding: 0;
  box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
  font-family: inherit; // Inherit font family from the parent element
}

html {
  font-size: 100%; // Sets a base font size (rem)
  line-height: 1.5; // Sets line-height to be more readable
}

body {
  background-color: #fff; // Default background color for the body
  color: #333; // Default text color
}

ol,
ul {
  list-style: none; // Remove list bullets and numbers
}

a {
  text-decoration: none; // Remove underline from links
  color: inherit; // Ensure links inherit color from their parent
}

img,
picture,
video,
svg {
  max-width: 100%;
  height: auto; // Ensure media elements are responsive
}

button,
input,
select,
textarea {
  background: none; // Remove default background from form elements
  border: none; // Remove default borders
  font-family: inherit; // Inherit font from parent (e.g., body)
  font-size: inherit; // Inherit font size from parent
  color: inherit; // Inherit text color
}

table {
  border-collapse: collapse; // Collapse table borders to avoid double borders
}

caption {
  text-align: center; // Align caption text to the center
}

hr {
  border: none; // Remove default border
  border-top: 1px solid #ddd; // Add a simple horizontal line
}

fieldset {
  border: none; // Remove border from fieldset
  padding: 0; // Remove padding
}

legend {
  display: none; // Hide legend element (optional, depending on use case)
}
