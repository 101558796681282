@use "vars.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

//flex properties
%flex,
.flex {
  display: flex;
}

%justify-center,
.justify-center {
  justify-content: center;
}

%justify-end,
.justify-end {
  justify-content: flex-end !important;
}

%justify-start,
.justify-start {
  justify-content: flex-start;
}

%justify-between,
.justify-between {
  justify-content: space-between;
}

%justify-evenly,
.justify-evenly {
  justify-content: space-evenly;
}

%justify-around,
.justify-around {
  justify-content: space-around;
}

%flex-centered,
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

%align-center,
.align-center {
  align-content: center;
}

%align-end,
.align-end {
  align-content: flex-end;
}

%align-start,
.align-start {
  align-content: flex-start;
}

%align-between,
.align-between {
  align-content: space-between;
}

%align-evenly,
.align-evenly {
  align-content: space-evenly;
}

%align-between,
.align-between {
  align-content: space-between;
}

%items-center,
.items-center {
  align-items: center;
}

%items-end,
.items-end {
  align-items: flex-end;
}

%items-start,
.items-start {
  align-items: flex-start;
}

%wrap,
.wrap {
  flex-wrap: wrap;
}

%no-wrap,
.no-wrap {
  flex-wrap: nowrap;
}

%column,
.column {
  flex-direction: column;
}

%flex-row,
.flex-row {
  flex-direction: row;
}

%row-reverse,
.row-reverse {
  flex-direction: row-reverse;
}

%column-reverse,
.column-reverse {
  flex-direction: column-reverse;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }

  .col-#{$i} {
    width: calc(8.33333% * #{$i});
  }
}

//@for $i from 1 through 12 {
//  .col-md-#{$i} {
//    width: calc(8.33333% * #{$i});
//  }
//  .col-sm-#{$i} {
//    width: calc(8.33333% * #{$i});
//  }
//  .col-lg-#{$i} {
//    width: calc(8.33333% * #{$i});
//  }
//}

@media (max-width: 576px) {
  @for $i from 1 through 12 {
    .col-xs-#{$i} {
      width: calc(8.33333% * #{$i});
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: calc(8.33333% * #{$i});
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      width: calc(8.33333% * #{$i});
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      width: calc(8.33333% * #{$i});
    }
  }
}

@media (min-width: 1200px) {
  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      width: calc(8.33333% * #{$i});
    }
  }
}

//paddings

.pa-xs {
  padding: vars.$xs;
}

.pa-sm {
  padding: vars.$sm;
}

.pa-md {
  padding: vars.$md;
}

.pa-lg {
  padding: vars.$lg;
}

.pa-none {
  padding: none;
}

.pa-xl {
  padding: vars.$xl;
}

//padding-top
.pt-xs {
  padding-top: vars.$xs;
}

.pt-sm {
  padding-top: vars.$sm;
}

.pt-md {
  padding-top: vars.$md;
}

.pt-lg {
  padding-top: vars.$lg;
}

.pt-xl {
  padding-top: vars.$xl;
}

//padding-right
.pr-xs {
  padding-right: vars.$xs;
}

.pr-sm {
  padding-right: vars.$sm;
}

.pr-md {
  padding-right: vars.$md;
}

.pr-lg {
  padding-right: vars.$lg;
}

.pr-xl {
  padding-right: vars.$xl;
}

//padding-left
.pl-xs {
  padding-left: vars.$xs;
}

.pl-sm {
  padding-left: vars.$sm;
}

.pl-md {
  padding-left: vars.$md;
}

.pl-lg {
  padding-left: vars.$lg;
}

.pl-xl {
  padding-left: vars.$xl;
}

//padding-bottom
.pb-xs {
  padding-bottom: vars.$xs;
}

.pb-sm {
  padding-bottom: vars.$sm;
}

.pb-md {
  padding-bottom: vars.$md;
}

.pb-lg {
  padding-bottom: vars.$lg;
}

.pb-xl {
  padding-bottom: vars.$xl;
}

//padding-x
.px-xs {
  padding-right: vars.$xs;
  padding-left: vars.$xs;
}

.px-sm {
  padding-right: vars.$sm;
  padding-left: vars.$sm;
}

.px-md {
  padding-right: vars.$md;
  padding-left: vars.$md;
}

.px-lg {
  padding-right: vars.$lg;
  padding-left: vars.$lg;
}

.px-xl {
  padding-right: vars.$xl;
  padding-left: vars.$xl;
}

//padding-y
.py-xs {
  padding-top: vars.$xs;
  padding-bottom: vars.$xs;
}

.py-sm {
  padding-top: vars.$sm;
  padding-bottom: vars.$sm;
}

.py-md {
  padding-top: vars.$md;
  padding-bottom: vars.$md;
}

.py-lg {
  padding-top: vars.$lg;
  padding-bottom: vars.$lg;
}

.py-xl {
  padding-top: vars.$xl;
  padding-bottom: vars.$xl;
}

//margin

.ma-xs {
  margin: vars.$xs;
}

.ma-sm {
  margin: vars.$sm;
}

.ma-md {
  margin: vars.$md;
}

.ma-lg {
  margin: vars.$lg;
}

.ma-xl {
  margin: vars.$xl;
}

//padding-top

.ma-none {
  margin: 0 !important;
}

.my-none {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-xs {
  margin-top: vars.$xs;
}

.mt-sm {
  margin-top: vars.$sm;
}

.mt-md {
  margin-top: vars.$md;
}

.mt-lg {
  margin-top: vars.$lg;
}

.mt-xl {
  margin-top: vars.$xl;
}

//padding-right
.mr-xs {
  margin-right: vars.$xs;
}

.mr-sm {
  margin-right: vars.$sm;
}

.mr-md {
  margin-right: vars.$md;
}

.mr-lg {
  margin-right: vars.$lg;
}

.mr-xl {
  margin-left: vars.$xl;
}

//padding-left
.ml-xs {
  margin-left: vars.$xs;
}

.ml-sm {
  margin-left: vars.$sm;
}

.ml-md {
  margin-left: vars.$md;
}

.ml-lg {
  margin-left: vars.$lg;
}

.ml-xl {
  margin-left: vars.$xl;
}

//padding-bottom
.mb-xs {
  margin-bottom: vars.$xs;
}

.mb-sm {
  margin-bottom: vars.$sm;
}

.mb-md {
  margin-bottom: vars.$md;
}

.mb-lg {
  margin-bottom: vars.$lg;
}

.mb-xl {
  margin-bottom: vars.$xl;
}

//padding-x
.mx-xs {
  margin-right: vars.$xs;
  margin-left: vars.$xs;
}

.mx-sm {
  margin-right: vars.$sm;
  margin-left: vars.$sm;
}

.mx-md {
  margin-right: vars.$md;
  margin-left: vars.$md;
}

.mx-lg {
  margin-right: vars.$lg;
  margin-left: vars.$lg;
}

.mx-xl {
  margin-right: vars.$xl;
  margin-left: vars.$xl;
}

//padding-y
.my-xs {
  margin-top: vars.$xs;
  margin-bottom: vars.$xs;
}

.my-sm {
  margin-top: vars.$sm;
  margin-bottom: vars.$sm;
}

.my-md {
  margin-top: vars.$md;
  margin-bottom: vars.$md;
}

.my-lg {
  margin-top: vars.$lg;
  margin-bottom: vars.$lg;
}

.my-xl {
  margin-top: vars.$xl;
  margin-bottom: vars.$xl;
}

//rows and columns
.row {
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.full-vh {
  height: 100vh !important;
}

//overflow
.auto-scroll {
  overflow: auto;
}

.x-auto-scroll {
  overflow-x: auto;
}

.y-auto-scroll {
  overflow-y: auto;
}

@for $i from 1 through 12 {
  .z#{$i} {
    z-index: $i;
  }
}

//positions
.sticky-top {
  position: sticky;
  top: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
}

.top-most {
  top: 0;
}

.left-most {
  left: 0;
}

.right-most {
  right: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.d-none {
  display: none;
}

//wrapper
.wrapper {
  padding: 5% vars.$wrapper-val;
}

.wrapper-y {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.wrapper-x {
  padding: 0 vars.$wrapper-val;
}

//overflow
.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

//images

.cover-center {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.contain-center {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

//nav
nav {
  height: 60px;
  // z-index: 999;
}

//logo

.logo {
  height: 50px;

  img {
    height: 100%;
    object-fit: contain;
    width: auto;
  }

  @media (max-width: 768px) {
    img {
      width: 100%;
    }
  }
}

//nav
nav.landing {
  position: fixed;
  top: 0;
  width: 100%;
  // z-index: 99;
  height: 8vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 vars.$wrapper-val;

  .nav-items {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.text-light {
    .logo {
      color: var(--text-light) !important;
    }

    .nav-item {
      color: var(--text-light) !important;
    }
  }
}

//overflow
.auto-scroll {
  overflow: auto;
}

.x-auto-scroll {
  overflow-x: auto;
}

.y-auto-scroll {
  overflow-y: auto;
}

//`.rumble-column-icon{
//  filter:invert(0) !important;
//}`
