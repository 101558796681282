@use "../scss/vars";

.filter-panel-container {
  padding-top: 8px;

  .ant-select-selector {
    border-radius: 0px !important;
    background: transparent !important;
    // border: 1px solid #d5d5d5 !important;
    border: 1px solid #5e5e5e !important;
    padding: 0 5px !important;
    width: 55px !important;
  }
  .ant-input-affix-wrapper-focused {
    outline: none !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #5e5e5e !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #5e5e5e;
    input {
      background: transparent !important;

      &::placeholder {
        color: #c2c2c2 !important;
      }
    }

    .ant-input-suffix {
      display: flex;
      flex-direction: column;
      justify-content: end !important;
      font-size: 7px;
    }
  }

  .ant-switch-checked {
    background: #00ddc0 !important;
  }

  .ant-switch-checked:focus {
    box-shadow: unset !important;
  }

  .ant-btn > span:last-child {
    font-family: Helvetica !important;
    margin-left: 4px;
  }

  input,
  .ant-select-selector {
    border-radius: 1px !important;
  }

  input {
    &::placeholder {
      color: #9b9b9b;
    }
  }

  .ant-select-arrow {
    font-size: 10px;
    color: #989898;
  }
  .crossBtn {
    font-size: 12px;
    margin-left: 4px;
    cursor: pointer;
  }

  .add-btn {
    font-size: 10px;
    font-weight: 600;
    width: fit-content;
    margin-top: 8px;
    border-radius: 2px;
    // background: var(--primary-color);
    background: #effbff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-btn:hover {
    color: black;
  }

  .switch-container {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    margin-top: 10px;

    .clear-filter {
      color: vars.$danger-color;
      cursor: pointer;
    }

    .switch {
      display: flex;
      gap: 4px;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
}
.ant-select-item-option-content {
  font-size: 10px;
}

.ant-select-selection-item {
  padding-right: 0 !important;
}

.v2-column-header {
  span.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.filter-panel-dark {
  .ant-select-selector,
  input {
    // background: #ced0d4 !important;
    background: #fff !important;

    color: #3c3b3b;
  }

  input {
    &::placeholder {
      color: #747d8a;
    }
  }

  .add-custom-btn {
    background: #515a67 !important;
    color: #d5d5d5 !important;
  }
}
