@use "scss/style.scss";
@use "components/index.scss";
@use "common.scss";
@use "scss/style-v2/index.scss" as index2;
@use "scss/style-v2/dark-mode.scss";
@use "scss/vars";

body {
  margin: 0;
  font-family: vars.$title-font !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-light.read-more {
  margin-top: 4px;
  color: white !important;
  a {
    color: var(--primary-color);
  }
}

.inverted {
  filter: invert(1) !important;
}
.uninverted {
  filter: invert(0) !important;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.ramble-title {
  padding: 10px 0;
}
.profile-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: inherit;
  padding-bottom: 5px;
}
.f-r {
  float: right;
}

.ant-tag {
  padding: 0 3px !important;
  line-height: 14px !important;
}

.ant-notification-notice-close {
  top: 22px !important;
  right: 9px !important;

  .ant-notification-notice-close-x {
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    font-size: medium !important;
    background-color: rgb(217, 217, 217, 0.4) !important ;
    padding: 5px;
    padding-top: 7px;
    font-weight: bold !important;
    border-radius: 50%;
    svg {
      color: white;
      font-weight: bold !important;
    }
  }
}

.rtm-notification {
  background-color: #ff644e;
  color: white !important;
  padding: 10px;
  .rtm-header {
    font-size: large;
    justify-content: space-between;
    color: white !important;
    line-height: normal;
  }
  .rtm-body {
    justify-content: space-between;
    color: white !important;
    font-weight: bold !important;
  }
  .rtm-footer {
    margin-top: 13px;
    // a {
    text-decoration: none !important;
    justify-content: space-between;
    cursor: pointer;
    color: white !important;
    font-weight: bold !important;

    // }
  }
}
