@use "../../../../style/scss/vars.scss" as vars;

.change-password-container {
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // p,
  // span,
  // div,
  // a,
  // label,
  // strong,
  // button,
  // form,
  // table,
  // td,
  // tr

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
    font-family: inherit; // Inherit font family from the parent element
  }

  //   border: 1px solid red;
  color: vars.$secondary-0;
  width: 90%;
  max-width: 589px;
  margin-top: auto;
  margin-bottom: auto;

  h1 {
    font-size: vars.$fs-2xl;
    font-weight: vars.$fw-bold;
    color: inherit;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 14px;
    border: 1px solid #e1e4ed;
    padding: 14px;
    border-radius: vars.$radius-lg;
    box-shadow: 0px 1px 4px 0px #19213d14;
  }
}
