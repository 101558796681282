@use "../../../../style/scss/vars.scss" as vars;

.auth-modal-login-form {
  &-wrapper {
    width: 100%;
    padding-top: 28px;
    padding-left: 40px;
    font-family: Poppins;
    background-color: #ffffff;
  }

  border: 1px solid #e1ede4;
  box-shadow: 0px 1px 4px 0px #19213d14;
  border-radius: vars.$radius-lg;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  font-family: Poppins;
  // display: flex;
  // flex-direction: column;
  // gap: 12px;

  &-error {
    color: #ff644e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    margin-bottom: 0;
    margin-top: 6px;
  }

  &-forgot-password {
    color: vars.$primary-0;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    display: block;
    text-align: left;
    width: fit-content;
    font-family: Poppins;
    margin-top: 12px;
  }

  &-button-container {
    display: flex;
    gap: 12px;
    width: fit-content;
    margin-left: auto;
    font-family: Poppins;
    margin-top: 12px;
  }

  &-title {
    color: vars.$secondary-0;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 36.4px */
    font-family: Poppins;
    margin-bottom: 16px;
  }

  &-footer {
    padding-top: 12px;
    position: absolute;
    bottom: 32px;
    width: 100%;
    color: #1d1d4e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-family: Poppins;

    a {
      color: #1d1d4e;
    }

    span {
      color: #1d1d4e;
      font-feature-settings: "liga" off, "clig" off;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      font-family: Poppins;
    }
  }
}
