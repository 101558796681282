@use "../../../../style/scss/vars.scss" as vars;

.successfulComponentWrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div,
  a,
  strong {
    margin: 0;
    padding: 0;
    box-sizing: border-box; // Ensures padding and border are included in the element's total width/height
    font-family: inherit; // Inherit font family from the parent element
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 592px;
  margin-inline: auto;
  text-align: center;

  a {
    display: inline-block;
  }

  .image {
    height: 95px;
    width: 112px;
    margin-bottom: 36px;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .main {
  }

  h1 {
    font-size: vars.$fs-2xl;
    font-weight: vars.$fw-bold;
    color: vars.$secondary-0;
    margin-bottom: 18px;
    line-height: 50.4px;
  }

  p {
    font-size: vars.$fs-md;
    color: vars.$secondary-0;
    margin-bottom: 32px;
    line-height: 24px;
    max-width: 50ch;
  }

  strong {
    font-weight: vars.$fw-semibold;
  }
}
