@use "../../../../style/scss/vars.scss" as vars;

.auth-modal-change-password {
  &-wrapper {
    width: 100%;
    padding-top: 28px;
    padding-inline: 40px;
    background-color: #ffffff;
  }

  border: 1px solid vars.$input-border;
  box-shadow: 0px 1px 4px 0px #19213d14;
  border-radius: vars.$radius-lg;
  padding: 14px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-forgot-password {
    color: vars.$primary-0;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  &-button-wrapper {
    align-self: flex-end;
    display: flex;
    gap: 8px;
  }

  &-title {
    color: vars.$secondary-0;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 36.4px */
  }

  &-footer {
    padding-top: 12px;
    position: absolute;
    bottom: 32px;
    width: 100%;
    color: #1d1d4e;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    &-link {
      color: #1d1d4e;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
    }
  }
}
