@use "../../../../style/scss/vars.scss" as vars;

.email-verification-container {
  color: vars.$secondary-0;
  width: 90%;
  max-width: 593px;
  // padding: 10% 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: auto;

  * {
    color: inherit;
    font-family: inherit;
  }

  h1 {
    font-size: vars.$fs-2xl;
    font-weight: vars.$fw-bold;
    margin-bottom: 16px;
  }

  &>p {
    line-height: 24px;
    padding-bottom: 32px;
  }

  strong {
    font-weight: vars.$fw-semibold;
  }

  form {
    width: 100%;
    max-width: 440px;

    border: 1px solid #e1e4ed;
    box-shadow: 0px 1px 4px 0px #19213d14;
    padding: 22px 24px;
    border-radius: vars.$radius-lg;

    h3 {
      font-size: vars.$fs-md;
      font-weight: vars.$fw-semibold;
      margin-bottom: 20px;
      line-height: 24px;
    }

    p.error-text {
      color: vars.$error-0;
      max-width: 392px;
      line-height: 21px;
      margin-bottom: 14px;
    }

    .otp-input {
      margin-bottom: 14px;
    }
  }

  .email-verification-footer {
    font-size: vars.$fs-md;
    margin-top: 30px;

    strong {
      font-weight: vars.$fw-medium;
      text-decoration: underline;
      cursor: pointer;
      display: block1;
    }
  }
}
