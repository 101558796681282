@use "../../../../style/scss/vars.scss" as vars;

.password-validity-info-container {
  margin-top: 2px;

  .title-text {
    font-weight: vars.$fw-semibold;
    margin-bottom: 8px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5.5px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    color: vars.$text-light-gray;
  }

  svg {
    height: 16px;
    width: 16px;
    margin: 0 !important;
  }

  svg.success {
    color: vars.$secondary-0;
  }

  .error {
    color: vars.$danger-color;
    margin: 0;
  }
}
