@use "../../../../style/scss/vars.scss" as vars;

.login-page-wrapper {
  width: 90%;
  flex: 1;
  padding-inline: 5%;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
    color: inherit;
  }

  a:hover {
    color: inherit;
  }

  .login-page-container {
    color: vars.$secondary-0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 94%;
    flex: 1;
    gap: 28px;
    margin-inline: auto;
    max-width: 592px;

    .line {
      border-top: 1px solid vars.$input-border;
      flex: 1;
      position: relative;
      top: 2px;
      max-width: 539px;
    }

    .login-page-title {
      font-size: 36px;
      font-weight: 700;
      line-height: 50.4px;
      text-align: left;
    }

    .or {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      text-align: left;
    }

    form {
      gap: 14px;
      border: 1px solid vars.$input-border;
      box-shadow: 0px 1px 4px 0px #19213d14;
      border-radius: vars.$radius-lg;
      padding: vars.$md;
      display: flex;
      flex-direction: column;

      button {
        color: white;
      }
    }

    .login-page-subtitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: vars.$secondary-0;
    }

    .sign-up-with-google-btn {
      width: fit-content;
      border: 1px solid #e1e4ed;
      background-color: white;
      color: #0000008a;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      display: flex;
      gap: 14px;
      padding-inline: 24px;
      padding-block: 16px;
      border-radius: 10px;
    }
  }
}
