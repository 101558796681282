.polymarket-container {
  .iframe-container {
    margin-left: 4px;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    background: white;
    margin-top: 2px;
    position: relative;

    .loading {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    iframe {
      width: 100%;
      height: calc(100% - 3px);
    }
  }
}

.polymarket-container.dark {
  .iframe-container {
    background: #1d2b39;

    .loading {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #1d2b39;
      color: white;
    }

    iframe {
      border: none !important;
    }
  }
}
