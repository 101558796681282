.order-type-items {
  display: flex;
}

.num-values {
  display: flex;
  justify-content: space-between;
  .value {
    background-color: rgba(137, 208, 240, 0.2);
    padding: 10px;
    margin-right: 5px;
  }
}

.lg-modal {
  max-width: 700px !important;
}

.place-trade-modal {
  .modal-footer {
    justify-content: space-between;
  }
  .modal-content {
    padding: 5px;
  }
}

.input-view {
  padding: 12px;
}
.limit_distance {
  padding: 7px;
}
.stop_distance {
  padding: 7px;
}
.order-form {
  justify-content: space-between;
}
.footer-info {
  display: flex;
  .footer-item {
    display: flex;
    color: var(--text-light);
    margin-right: 14px;
    .footer-item-label {
      font-size: 16px;
      font-weight: bold;
      margin-right: 5px;
    }
    .footer-item-value {
      font-size: 16px;
      /* margin-top: 1px; */
    }
  }
}

.stop-loss-price {
  margin-bottom: 10px;
}

.take-profit-price {
  margin-bottom: 10px;
}

.box-input {
  width: 100px;
  height: 40px;
  padding: 10px;
  margin: 5px;
}
